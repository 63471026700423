import React from 'react';
import SidebarMenu from "../components/header/sidebar-menu";

const NoMatch = () => {
    return (
        <>
        <SidebarMenu menuType="left" />
        <SidebarMenu menuType="right" />
      <div className='height-page no-found ' style={{textAlign:"center"}}>
        <div className=' slide-in row'>
           <div>404 <br></br><span className='textnofoundPage'>Page Not Found</span></div>   
           
        </div>

       
        </div>
        </>
    );
}

export default NoMatch;
