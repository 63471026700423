import React from "react";

export const ActionTypes = {
  SET_PAGES: "SET_PAGES",
  SELECTED_WHY_PAGE: "SELECTED_WHY_PAGE",
  SELECTED_PROCESS_PAGE: "SELECTED_PROCESS_PAGE",
  SELECTED_CONNECT_PAGE: "SELECTED_CONNECT_PAGE",
  SELECTED_FAQ_PAGE: "SELECTED_FAQ_PAGE",
  SELECTED_stockists_PAGE: "SELECTED_stockists_PAGE",

  SET_PRODUCTS: "SET_PRODUCTS",
  SELECTED_PRODUCT: "SELECTED_PRODUCT",

  SELECTED_PRODUCT_VARIATION: "SELECTED_PRODUCT_VARIATION",

  ADD_TO_CART: "ADD_TO_CART",
};
