import React, { useEffect, useState } from "react";
import SidebarMenu from "../components/header/sidebar-menu";
import { NavLink } from "react-router-dom";
import bottonRightLogo from "../assets/images/right-bottom-logo.png";
import plusicon from "../assets/images/icons/plus-icon.png";
import minusicon from "../assets/images/icons/minus-icon.png";
import logo from '../assets/images/logocontent.png'
import { PageApi, getMediaById } from "../axiosCalls/pages";
import { useDispatch, useSelector } from "react-redux";
import { setPage } from "../redux/actions/pagesActions";
import ReactHtmlParser from "react-html-parser";

const WhyPage = () => {
  let excerpt = "",
    content = "",
    title = "";
  const page = useSelector((state) => state);

  const [loading, setLoading] = useState(true);

  const [allowContent, setallowContent] = useState(false);

  const image = page.page.page[0].image ? page.page.page[0].image : "";

  if (image && loading) {
    setLoading(false);
  }
  if (image) {
    excerpt = page.page.page[0].excerpt
      ? removeHtmlTags(page.page.page[0].excerpt)
      : "";
    content = page.page.page[0].content
      ? removeHtmlTags(page.page.page[0].content)
      : "";
    title = page.page.page[0].title
      ? removeHtmlTags(page.page.page[0].title)
      : "";
  }
  const showContent = () => {
    if (allowContent) {
      // document.getElementById("page-height").style.height = "100vh";

      setallowContent(false);
    } else {
      // document.getElementById("page-height").style.height = "auto";

      setallowContent(true);
    }
  };

  return (
    <>
      {loading ? (
        <>
          <div className="overlay-loader"></div>
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        </>
      ) : (
        <>
          <SidebarMenu menuType="left" />
          <SidebarMenu menuType="right" />

          <div id="page-height" className="row why-page slide-in ">
            <div className="col-md-6 leftSide-center-text top-border">
              <div className="whypage-center-text">
                <h1 className="whypage-heading">Behind leskoy</h1>
                <h3 className="whypage-sub-heading">
                  {"Be Less Coy"}
                  <br />
                  Scarves that Let Your Personality Soar
                </h3>

                {allowContent ? (
                  <p className="excerpt" id="page-heights">
                    {ReactHtmlParser(content)}
                  </p>
                ) : (
                  <p className="excerpt" id="page-height-exerpt">
                    {ReactHtmlParser(excerpt)}
                  </p>
                )}

                    <div className="content-bottom-logo">
                   <NavLink to='/scarves' > <img src={logo} className="img-bottom-logo" /></NavLink> 
                   
                      <div className="plus-icon" onClick={showContent}>
                  <img src={allowContent ? minusicon : plusicon} />
                </div>
                      </div>

               


              </div>
            </div>

            <div
              className="col-md-6 right"
              style={{ backgroundImage: `url(${image})` }}
            >
              <div className="content-pages-heading">
                <h2 className="PageTitleMobile">why</h2>
                {/* <img className="bottom-right-logo" src={bottonRightLogo} /> */}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
function removeHtmlTags(html) {
  const doc = new DOMParser().parseFromString(html, "text/html");
  const body = doc.body;

  const brTags = body.getElementsByTagName("br");
  for (let i = brTags.length - 1; i >= 0; i--) {
    const brTag = brTags[i];
    brTag.parentNode.removeChild(brTag);
  }

  return body.innerHTML;
}

export default WhyPage;
