import React,{useState}from "react";
import { Button, Form } from "react-bootstrap";
import logo from "../assets/images/logo.png";
import { NavLink, useNavigate } from "react-router-dom";
import { UpdatePasswordApi } from "../axiosCalls/auth";
import NotificationContainer from "react-notifications/lib/NotificationContainer";
import NotificationManager from "react-notifications/lib/NotificationManager";

const UpdatePassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formValue, setFormValue] = useState({
    password: "",
    confirmpassword: "",
  });
  const [errors, setErrors] = useState({});
  const handleOnchange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  const submitHandler = async () => {
    const { password, confirmpassword } = formValue;
    let errors = {};
     if (!password) {
      errors["password"] = "Password is required";
      setErrors(errors);
    } else if (!confirmpassword) {
      errors["confrimPassword"] = "Confirm Password is required";
      setErrors(errors);
    } else if (password && confirmpassword) {
      if (!password.match(confirmpassword)) {
        errors["confrimPassword"] =
          "Confirm password and password doesn't match ";
          setErrors(errors);
      } else if (Object.keys(errors).length <= 0) {
        setLoading(true)
        errors["confrimPassword"] = "";

        setErrors(errors);
        const email = localStorage.getItem("email-otp");
        const code = localStorage.getItem("code-otp");
        
        await UpdatePasswordApi(email,code,password).then((res) => {
          // localStorage.setItem('token',res.data.data.token);
            localStorage.removeItem('code-otp');
            localStorage.removeItem('email-otp');
            setLoading(false)
           NotificationManager.success(
            res.data.message,"",1000
          )
          setTimeout(function() {
          navigate("/login");
        }, 3000);
        }).catch((err)=>{
          setLoading(false)
        });
      } else {
        setErrors(errors);
      }
    }
  };
  onkeydown = (e) => {
    if (e.keyCode === 13) {
    submitHandler();
    }
  };
  return (
    <>
     {loading &&
    <>
    <div className="myaccount-loader"> 
    <div className="overlay-loader" key="005"> </div>
    <div className="spinner" key="006"></div>
    </div>
  </>
    }
      <div className="center forgot-page">
      <NavLink to='/home'>
        <img className="logo" src={logo} />
      </NavLink>      </div>

      <div className="center-form slide-in">
        <h1 className="main-heading">Update Password</h1>
        <p className="sub-heading">
          Please enter the email address associated with your account.
        </p>

        <Form>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              name="password"
              placeholder="Password"
              onChange={handleOnchange}
            />
            <Form.Text className="text-muted">
              {errors.password && (
                <p className="errorText">{errors.password}</p>
              )}
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicConfirnPassword">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              name="confirmpassword"
              placeholder="Confirm Password"
              onChange={handleOnchange}
            />
            <Form.Text className="text-muted">
              {errors.confrimPassword && (
                <p className="errorText">{errors.confrimPassword}</p>
              )}
            </Form.Text>
          </Form.Group>

          <Button
            className="submit-button"
            type="button"
            onClick={submitHandler}
          >
            Update
          </Button>

          <NavLink
            className="login-here center"
            style={{ marginTop: "20px" }}
            to="/login"
          >
            Back To Login
          </NavLink>
        </Form>
      </div>
      <NotificationContainer />
    </>
  );
};

export default UpdatePassword;
