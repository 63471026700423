import React from "react";
import HeaderLanding from "../header/header-landing";
import FooterLanding from "../footer/footer-landing";

const LandingLayout = (props) => {
  const { childCom } = props; // Destructuring the childCom prop from the props object and assigning it to a variable

  return (
    <>

      <HeaderLanding /> {/*Rendering the Header component*/}
      {childCom} {/* Child Component which is main page */}
      <FooterLanding /> {/*Rendering the Footer component*/}
      
    </>
  );
};

export default LandingLayout;
