import React from 'react';
 import SidebarMenu from '../components/header/sidebar-menu';


const PlayTemp = () => {
    return (
        
        <>
        <SidebarMenu menuType="left" />
    <SidebarMenu menuType="right" />
      <div className='slide-'>
            

      <div id="overlay"></div>
      <div  >
          <div className='play-top-temp-text'>How will you Play? Where will you Play? Show off your scarf and #play #belesscoy</div> 
      <div className="centered"></div>

      </div></div></>
    );
}

export default PlayTemp;
