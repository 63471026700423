import React, { useState } from "react";
import { Navbar, Container, Row, Col } from "react-bootstrap";
import menuIcon from "../../assets/images/menu-icon.png";
import logo from "../../assets/images/logo.png";
import fbIcon from "../../assets/images/icons/fb-icon.png";
import instaIcon from "../../assets/images/icons/insta-icon.png";
import twIcon from "../../assets/images/icons/tw-icon.png";
import Menu from "../header/menu";
import { NavLink } from "react-router-dom";

const Footer = () => {
  const mobileScreenWidth = 767;
  const [mobileMenuToggle, SetMobileMenuToggle] = useState(true);

  const [menuToggle, SetmenuToggle] = useState(true);

  const footerToggle = () => {
    // console.log(menuToggle);
    if (menuToggle) {
      document.getElementById("FootermyNav").style.height = "33%";


      setTimeout(() => {
        document.getElementById("FootermyNav").style.height = "fit-content";

      }, 400);
      document.getElementById("myNav").style.height = "0%";
      SetmenuToggle(false);
    } else {
      document.getElementById("FootermyNav").style.height = "0%";
      SetmenuToggle(true);
    }
  };

  const handlerMobileToggle = () => {
    if (mobileMenuToggle) {
      document.getElementById("rightMobileMenu").style.visibility = "visible";
      document.getElementById("rightMobileMenu").style.opacity = "1";

      document.getElementById("leftMobileMenu").style.visibility = "hidden";
      document.getElementById("leftMobileMenu").style.opacity = "0";

      SetMobileMenuToggle(false);
    }
    if (!mobileMenuToggle) {
      document.getElementById("rightMobileMenu").style.visibility = "hidden";
      document.getElementById("rightMobileMenu").style.opacity = "0";
      SetMobileMenuToggle(true);
    }
  };
  const closeMenu = () =>{
    document.getElementById("FootermyNav").style.height = "0%";
    document.getElementById("myNav").style.height = "0%";
    // SetmenuToggle(true);
  }
  return (
    <>
       
       <div className="mobileScreen">

     
        {/* // mobile Design */}
        <Row className="main-footer ">
          <Col className="col-footer-1">
            <div className="footer-centerPart">
              <p className="">Be less coy</p>
            </div>
            <div className="copyright-text">
              <p>©2023-Present Leskoy, Inc. All rights reserved.</p>
            </div>
          </Col>

          <Col>
            <NavLink to="/">
              {" "}
              <img className="footer-Logo" src={logo} />
            </NavLink>
            <div className="social-icons">
              <a
                href="https://www.facebook.com/profile.php?id=100089919040070"
                target="_blank"
              >
                <img style={{ width: 8 }} src={fbIcon} />
              </a>
              <a href="https://www.instagram.com/belesscoy/" target="_blank">
                <img src={instaIcon} />
              </a>
              <a href="https://twitter.com/Belesscoy" target="_blank">
                <img src={twIcon} />
              </a>
            </div>
          </Col>

          <Col className="main-headerRight">
            <img
              className="menuIcon"
              onClick={handlerMobileToggle}
              src={menuIcon}
            />
          </Col>
 
        </Row>
    </div>
        {/* // desktop design */}
        <div className="desktopScreen">

  
        <Row className="main-footer ">
          <Col className="main-headerLeft">
            <img
              className="menuIcon"
              onMouseEnter={footerToggle}
              src={menuIcon}
            />
          </Col>

          <Col className="main-footerCenter">
            <div className="footer-centerPart">
              <p className="">Be less coy</p>
              <NavLink to="/">
                {" "}
                <img src={logo} />
              </NavLink>
            </div>

            <div className="copyright-text">
              <p>©2023-Present Leskoy, Inc. All rights reserved.</p>
            </div>

            <div className="social-icons">
              <a
                href="https://www.facebook.com/profile.php?id=100089919040070"
                target="_blank"
              >
                <img style={{ width: 12 }} src={fbIcon} />
              </a>
              <a href="https://www.instagram.com/belesscoy/" target="_blank">
                <img src={instaIcon} />
              </a>
              <a href="https://twitter.com/Belesscoy" target="_blank">
                <img src={twIcon} />
              </a>
            </div>
          </Col>

          <Col className="main-headerRight">
            <img
              className="menuIcon"
              onMouseEnter={footerToggle}
              src={menuIcon}
            />
          </Col>

          <div className="overlay" id="FootermyNav" onMouseLeave={footerToggle}>
            <a href="#" className="closebtn" onClick={closeMenu}>
              &times;
            </a>

            <div className="overlay-content menuitem">
              <Menu />
            </div>
          </div>
        </Row>

              </div>
 
    </>
  );
};

export default Footer;
