import React, { useState } from 'react';
import { NavLink,Link } from 'react-router-dom';
import bottonRightLogo from "../../assets/images/right-bottom-logo.png";


const SidebarMenu = (props) => {
    const mobileScreenWidth = 767;

    const [state, setstate] = useState(true);

     
    const { menuType } = props;
    

    if (menuType == 'left') {
        return (
            <>
                <ul className="menu left desktopScreen">
                    <li className="menu_i"><NavLink to="/why" className="nav-link"> <span className="menu_i_tx ">Why</span></NavLink></li>
                    <li className="menu_i"><NavLink to="/process" className="nav-link"><span className="menu_i_tx">Process</span></NavLink></li>
                    <li className="menu_i "><NavLink to="/connect" className="nav-link"><span className="menu_i_tx connect">Connect</span></NavLink></li>
                    <li className="menu_i"><NavLink to="/faq" className="nav-link"><span className="menu_i_tx">Faq</span></NavLink></li>
                    <li className='menu_i'>   <img className="" src={bottonRightLogo} /></li>
                </ul>

                <div className='leftMobileMenu mobileScreen' id='leftMobileMenu'>
            <ul className="menu left">
                <li className="menu_i"><NavLink to="/why" className="nav-link"> <span className="menu_i_tx ">Why</span></NavLink></li>
                <li className="menu_i"><NavLink to="/process" className="nav-link"><span className="menu_i_tx">Process</span></NavLink></li>
                <li className="menu_i"><NavLink to="/connect" className="nav-link"><span className="menu_i_tx">Connect</span></NavLink></li>
                <li className="menu_i"><NavLink to="/faq" className="nav-link"><span className="menu_i_tx">Faq</span></NavLink></li>
             </ul>
            </div>

            </>
        );
    } else if (menuType == 'right') {

        return (
            <>
                <ul className="menu right desktopScreen">
                <li className="menu_i"> <NavLink to="/home"><span className="menu_i_tx">{" "}</span></NavLink></li>
                    <li className="menu_i"> <NavLink to="/scarves"><span className="menu_i_tx">Scarves</span></NavLink></li>
                    <li className="menu_i"><NavLink to="/play"><span className="menu_i_tx">Play</span></NavLink></li>
                    <li className="menu_i"><NavLink to="/stockists"><span className="menu_i_tx">Stockists</span></NavLink></li>
                     <li className='menu_i'>   <img className="" src={bottonRightLogo} /></li>
                

                </ul>


                <div className='rightMobileMenu mobileScreen' id='rightMobileMenu'>
                   <ul className="menu right">
                     <li className="menu_i"> <NavLink to="/scarves"><span className="menu_i_tx">Scarves</span></NavLink></li>
                    <li className="menu_i"><NavLink to="/play"><span className="menu_i_tx">Play</span></NavLink></li>
                    <li className="menu_i"><NavLink to="/stockists"><span className="menu_i_tx">Stockists</span></NavLink></li>
                 

                </ul>
                   </div>
            </>
        );


    }

 
  
 

    
  
       
}

export default SidebarMenu;
