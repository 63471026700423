import React from "react"; // Importing the React library
import greenLogo from "../assets/images/greenlogo.png";
import SidebarMenu from "../components/header/sidebar-menu";
import { NavLink } from "react-router-dom";

function Home() {
  // Defining a functional component called Home
  return (
    <>
      <SidebarMenu menuType="left" />
      <SidebarMenu menuType="right" />

      <div className="row home-page slide-in" >
        <div className="col-md-6 left">
          <div className="hideOnMobile home-page-sub-heading ">
            <div>
          <NavLink to="/scarves" >
         
            <h1 className="sub-heading">Shop the Collection</h1>
          </NavLink></div>
        </div></div>

        <div className="col-md-6 right">
        <NavLink to="/scarves" className="sub-mobile-heading">
         
         <h1 className="">Shop the Collection</h1>
       </NavLink>

        </div>
      </div>
    </>
  );
}

export default Home; // Exporting the Home component as the default export
