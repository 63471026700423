import { ActionTypes } from "../constants/action-types";

const intialState = {
  page: [],
};
const productintalState = {
  products: [],
};
const productSingleintalState = {
  product: [],
};
const productvariintalState = {
  vari: [],
};
const addtocartintalState = {
  addtocart: [],
};
export const whyPageReducer = (state = intialState, { type, payload, image }) => {
  switch (type) {
    case ActionTypes.SELECTED_WHY_PAGE:
      return { ...state, page: payload, image: image };

    default:
      return state;
  }
};

export const processPageReducer = (state = intialState, { type, payload, image }) => {
  switch (type) {
    case ActionTypes.SELECTED_PROCESS_PAGE:
      return { ...state, page: payload, image: image };

    default:
      return state;
  }
};


export const connectPageReducer = (state = intialState, { type, payload, image }) => {
  switch (type) {
    case ActionTypes.SELECTED_CONNECT_PAGE:
      return { ...state, page: payload, image: image };

    default:
      return state;
  }
};

export const faqPageReducer = (state = intialState, { type, payload, image }) => {
  switch (type) {
    case ActionTypes.SELECTED_FAQ_PAGE:
      return { ...state, page: payload, image: image };

    default:
      return state;
  }
};



export const productReducer = (
  state = productintalState,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.SET_PRODUCTS:
      return { ...state, products: payload };
    default:
      return state;
  }
};
export const productSelectedReducer = (
  state = productSingleintalState,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.SELECTED_PRODUCT:
      return { ...state, product: payload };
    default:
      return state;
  }
};
export const productvaritionReducer = (
  state = productvariintalState,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.SELECTED_PRODUCT_VARIATION:
      return { ...state, vari: payload };
    default:
      return state;
  }
};
export const addtocartReducer = (
  state = addtocartintalState,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.ADD_TO_CART:
      return { ...state, addtocart: payload };
    default:
      return state;
  }
};
