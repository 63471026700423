import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

const Menu = () => {

    const shoot = () => {

      document.getElementById("myNav").style.height = "0%";
      document.getElementById("FootermyNav").style.height = "0%";



  }

    return (
        <>

            <NavLink className="nav-link" onClick={shoot} to="/scarves">Scarves</NavLink>
            <NavLink className="nav-link" onClick={shoot} to="/why">Why</NavLink>
            <NavLink className="nav-link" onClick={shoot} to="/process">Process</NavLink>
            <NavLink className="nav-link" onClick={shoot}  to="/connect">Connect</NavLink>
            <NavLink className="nav-link" onClick={shoot}  to="/faq">Faq</NavLink>
        </>
    );
}

export default Menu;
