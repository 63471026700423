import React, { useState } from "react";
import { NavLink, Navbar, Container, Row, Col } from "react-bootstrap";
import menuIcon from '../../assets/images/menu-icon.png';
import Menu from "./menu";

const HeaderLanding = () => {
  const [menuToggle, SetmenuToggle] = useState(true);

  const shoot = () => {
    // console.log(menuToggle);
    if (menuToggle) {
      document.getElementById("myNav").style.height = "33%";
      setTimeout(() => {
        document.getElementById("myNav").style.height = "fit-content";

      }, 400);
      document.getElementById("FootermyNav").style.height = "0%";
      SetmenuToggle(false);
    }
    else {
      document.getElementById("myNav").style.height = "0%";
      SetmenuToggle(true);
    }

  }
  const closeMenu = () =>{
    document.getElementById("FootermyNav").style.height = "0%";
    document.getElementById("myNav").style.height = "0%";
    // SetmenuToggle(true);
  }
  return (
    <>
  <div  id="cart-box" ></div>
      <Row className="header" > 

        <Col className="headerLeft">
          <img className="menuIcon" onMouseEnter={shoot} src={menuIcon} />
        </Col>


        <Col className="headerRight">
          <img className="menuIcon" onMouseEnter={shoot} src={menuIcon} />
        </Col>


        <div className="overlay" id="myNav" onMouseLeave={shoot}>
          <a href="#" className="closebtn" onClick={closeMenu} >&times;</a>

          <div className="overlay-content menuitem">

            <Menu />

          </div>
        </div>
      </Row>



    </>
  );
}

export default HeaderLanding;
