import React from "react";
import SidebarMenu from "../components/header/sidebar-menu";
import { NavLink } from "react-router-dom";
import bottonRightLogo from "../assets/images/right-bottom-logo.png";
 
import { useSelector } from "react-redux";

const ScarvesPage = () => {

  const getProducts = useSelector((state)=>state);
  const products = getProducts.products.products;
 
   return (
    <>
      <SidebarMenu menuType="left" />
      <SidebarMenu menuType="right" />

      <div className="row scarves-page">
        <NavLink to="#">
          {" "}
          {/* <h1 className="sub-heading">Shop the Collection</h1> */}
        </NavLink>

        <div className="col-md-1"></div>

        <div className="col-md-11 center-page " style={{height:"850px",overflowY:"auto"}}>
          <div className="row"> 

          <div className="col-md-1">
 <div  className="discover-text">
                     <div>Discover Your Scarf</div>
          </div>
          
          </div>
          <div className="col-md-11">
            <div className="ScarvesLoop">
                   <h2 className="PageTitleMobileScarves">scarves</h2>

          <div className="row  slide-in" style={{ padding: "3% 0" }}>


           {products.slice().reverse().map((product,index)=>{
              return(

<>    
{product.status == 'publish' && product.type == 'simple' ? <> <div className="col-md-1 scrves-page-mobile-1" style={{ width: "10%" }} >
 
</div>

            <div className="col-md-11 scrves-page-mobile-2" style={{ width: "90%" }}  >
            <NavLink to={`/scarves/${product.id}`} id={product.id}  >
              <span className="product-title-on-Img">{product.name}</span>
              <img className="product-img v-1" src={product.images[0].src}  />
              <NavLink to={`/scarves/${product.id}`} id={product.id} className="shop-now">
                Buy Me
              </NavLink>
              </NavLink>
            </div>
<div className="spacer"></div></> : ''}
           
 
</>
              )})}



            
            {/* <div className="col-md-1" style={{ width: "4.5%" }}></div>
            <div className="col-md-11" style={{ width: "95.5%" }}>
              <img className="product-img v-1" src={product_2_img} />
              <NavLink to="" className="shop-now">
                Shop Now
              </NavLink>
            </div> */}


          </div>

          </div>
          </div>

          
  </div>
          <div className="bottomLogo">
            {/* <img className="bottom-right-logo" src={bottonRightLogo} /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ScarvesPage;
