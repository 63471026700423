import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom"; // Importing the BrowserRouter, Routes, Route, and Navigate components from react-router-dom
import Layout from "./components/layouts"; // Importing the Layout component from a file in a subdirectory called "components/layouts"
import Home from "./pages/home"; // Importing the Home component from a file in a subdirectory called "pages"
import Landing from "./pages/landing";
import LandingLayout from "./components/layouts/landing-layout";
import Login from "./pages/login";
import AuthPages from "./components/layouts/auth-pages";
import Signup from "./pages/signup";
import ForgotPassword from "./pages/forgot-password";
import UpdatePassword from "./pages/update-password";
import WhyPage from "./pages/why-page";
import ProcessPage from "./pages/process-page";
import ScarvesPage from "./pages/scarves-page";
import NoMatch from "./pages/404";
import Stockists from "./pages/stockists";
import Play from "./pages/play";
import MyAccount from "./pages/my-account";
import Connect from "./pages/connect";
import Faq from "./pages/faq";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import VerficationOtp from "./pages/verfication-otp";
import Checkout from "./pages/checkout";
import ScarvesDetailPage from "./pages/scarves-detail-page";
import { isLogin } from "./components/includes/utils";
import PlayTemp from "./pages/play-temp";
import PaymentElements from "./components/includes/paymentElement";
import StockistsTemp from "./pages/stockists-temp";

function CreateRoutes() {
  const location = useLocation();

  // Defining a functional component called CreateRoutes
  return (
    <>
      {/* // Wrapping the routes in a Router component from react-router-dom */}

      {/* // Defining the routes for the application using the Routes component from react-router-dom */}

      <Routes>
        {/* Landing pages */}
        <Route path="/" element={<LandingLayout childCom={<Landing />} />} />

        {/* Authpages */}

        <Route path="/login" element={<AuthPages childCom={<Login />} />} />
        <Route path="/sign-up" element={<AuthPages childCom={<Signup />} />} />
        <Route
          path="/forgot-password"
          element={<AuthPages childCom={<ForgotPassword />} />}
        />
        <Route
          path="/update-password"
          element={<AuthPages childCom={<UpdatePassword />} />}
        />
        <Route
          path="/verify-otp"
          element={<AuthPages childCom={<VerficationOtp />} />}
        />

        <Route
          path="/my-account"
          element={isLogin && <Layout childCom={<MyAccount />} />}
        />

        {/* Other pages */}

        <Route path="/home" element={<Layout childCom={<Home />} />} />
        <Route path="/why" element={<Layout childCom={<WhyPage />} />} />
        <Route
          path="/process"
          element={<Layout childCom={<ProcessPage />} />}
        />
        <Route
          path="/scarves"
          element={<Layout childCom={<ScarvesPage />} />}
        />
        <Route
          path="/scarves/:id"
          element={<Layout childCom={<ScarvesDetailPage />} />}
        />

        <Route
          path="/checkout"
          element={<Layout childCom={<PaymentElements />} />}
        />

        <Route
          path="/stockists"
          element={<Layout childCom={<StockistsTemp />} />}
        />
        {/* <Route path="/play" element={<Layout childCom={<Play />} />} /> */}
        <Route path="/connect" element={<Layout childCom={<Connect />} />} />
        <Route path="/faq" element={<Layout childCom={<Faq />} />} />
        <Route path="/play" element={<Layout childCom={<PlayTemp />} />} />

        {/* <Route path="/play/:id" element={<Layout childCom={<Play />} />} /> */}

        {/* 404 Page */}
        <Route path="*" element={<Layout childCom={<NoMatch />} />} />
      </Routes>
    </>
  );
}

export default CreateRoutes;
