import React from 'react';
 import SidebarMenu from '../components/header/sidebar-menu';


const StockistsTemp = () => {
    return (
        <>
          <SidebarMenu menuType="left" />
      <SidebarMenu menuType="right" />
        <div className='slide-'>
          
        <div id="overlay"></div>

        <div  >
        <div className="centered">Coming Soon</div>

        </div></div></>
    );
}

export default StockistsTemp;
