import { combineReducers } from "redux";
import {
  productReducer,
  whyPageReducer,
  processPageReducer,
  productSelectedReducer,
  productvaritionReducer,
  addtocartReducer,
  faqPageReducer,
  connectPageReducer,
  
} from "./pagesReducer";

const reducers = combineReducers({
  page: whyPageReducer,
  processPage:processPageReducer,
  connectPage:connectPageReducer,
  faqPage:faqPageReducer,
  products: productReducer,
  product: productSelectedReducer,
  variation: productvaritionReducer,
  addtocart: addtocartReducer,
});

export default reducers;
