import { ActionTypes } from "../constants/action-types"

export const setWhyPage = (page,image) =>{
return{
    type:ActionTypes.SELECTED_WHY_PAGE,
    payload: page,
    image:image
}

}

export const setProcessPage = (page,image) =>{
    return{
        type:ActionTypes.SELECTED_PROCESS_PAGE,
        payload: page,
        image:image
    }
    
    }
    
export const setConnectPage = (page,image) =>{
    return{
        type:ActionTypes.SELECTED_CONNECT_PAGE,
        payload: page,
        image:image
    }
    
    }
    export const setFaqPage = (page,image) =>{
        return{
            type:ActionTypes.SELECTED_FAQ_PAGE,
            payload: page,
            image:image
        }
        
        }
export const setProducts = (products) =>{
    return{
        type:ActionTypes.SET_PRODUCTS,
        payload:products,
    }
}

export const setSelectedProduct = (product) =>{
    return{
        type:ActionTypes.SELECTED_PRODUCT,
        payload:product,
    }
}

export const setSelectedVariation = (variation) =>{
    return{
        type:ActionTypes.SELECTED_PRODUCT_VARIATION,
        payload:variation,
    }
}


export const setAddToCart = (product) =>{
    return{
        type:ActionTypes.ADD_TO_CART,
        payload:product,
    }
}

