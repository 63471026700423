import axios from "axios";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SITE_API_KEY+'/wp-json',
});

axiosInstance.interceptors.request.use((config) => {
  /* eslint-disable no-param-reassign */
  var token = localStorage.getItem("token");


  // if(!token){
  //   token = localStorage.getItem("cart_key");
  // }

  config.headers = {
    Authorization : "Basic"+token,
     "Content-Type": "application/json",
     Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
  };

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log("error::", error.response.data.message);

    NotificationManager.error(error.response.data.message,"",9000);

    // return error;

    return Promise.reject(error.response.data);
  }
);
