import React, { useState } from "react";
import { Navbar, Container, Row, Col } from "react-bootstrap";
import menuIcon from "../../assets/images/menu-icon.png";
import logo from "../../assets/images/logo.png";
import cartIcon from "../../assets/images/icons/cart-icon.png";
import accountIcon from "../../assets/images/icons/icon-user.svg";
import Menu from "./menu";
import { NavLink } from "react-router-dom";
import CartSliding from "../includes/cart-sliding";
import { isLogin } from "../includes/utils";
import { useDispatch, useSelector } from "react-redux";
import { getCartApi } from "../../axiosCalls/pages";
import { setAddToCart } from "../../redux/actions/pagesActions";

const Header = () => {
  const mobileScreenWidth = 767;

  const dispatch = useDispatch();
  const [menuToggle, SetmenuToggle] = useState(true);
  const [mobileMenuToggle, SetMobileMenuToggle] = useState(true);

  const [cartToggle, SetcartToggle] = useState(false);
  const [searchPopup, setSearchpopup] = useState(false);
  const [results, setResults] = useState([]);
  

  const products = useSelector((state) => state).products.products;
  const pages = useSelector((state) => state);

   
  const cartDataItems =  pages.addtocart.addtocart ;

  setTimeout(() => {
    
  }, 1000);

  const shoot = () => {
    if (menuToggle) {
      document.getElementById("myNav").style.height = "33%";
      setTimeout(() => {
        document.getElementById("myNav").style.height = "fit-content";

      }, 400);
      document.getElementById("FootermyNav").style.height = "0%";
      SetmenuToggle(false);
    } else {
      document.getElementById("myNav").style.height = "0%";
      SetmenuToggle(true);
    }
  };
  const closeMenu = () => {
    document.getElementById("FootermyNav").style.height = "0%";
    document.getElementById("myNav").style.height = "0%";
    // SetmenuToggle(true);
  };
  const handlerSearchPopup = (e) => {
    const searchValue = e.target.value;
    if (e.target.value.length === 0) {
      setSearchpopup(false);
    }

    if (e.target.value.length >= 1) {
      setSearchpopup(true);
      performSearch(searchValue);
    }
  };

  const allItems = [].concat(
    ...pages.page.page,
    pages.processPage.page,
    pages.connectPage.page,
    pages.faqPage.page,
    products
  ); // Concatenate all the arrays into a single array

  const performSearch = (searchQuery) => {
    const filteredResults = allItems.filter((item) =>
      item.status == "publish"
        ? String(item.name)
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          String(item.description)
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          String(item.price)
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          String(item.title)
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          String(item.content)
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          String(item.excerpt)
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          String(item.slug)
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        : ""
    );

    const formattedResults = filteredResults.map((item) => ({
      id: item.id,
      name: item.type ? item.name : item.title,
      featuredImg: item.type
        ? item.images[0]
          ? item.images[0].src
          : logo
        : item.image,
      type: item.type ? "product" : "page",
      slug: !item.type && item.slug,
    }));

    setResults(formattedResults);
  };

   const getCartData = async () => {
    const cartKey = localStorage.getItem("cart_key");
    // const res = await getCartApi(cartKey);

    // localStorage.setItem("cart_items", JSON.stringify(res.data));
    // dispatch(setAddToCart(res.data));
  };
  const handlerClick = (event) => {
    if(event.target.id !== 'not-hide'  ) {
      setSearchpopup(false);
     }
    
  };
  const cartHandler = () => {

    if (localStorage.getItem("cart-toggal") === "false") {
      SetcartToggle(true);
    }
if(document.getElementById("cart-box").style.width === '' || document.getElementById("cart-box").style.width === '0px' ){
  localStorage.setItem("cart-toggal", true);
  const mobileScreenWidth = 767;    getCartData();

  if (window.innerWidth <= mobileScreenWidth) {

    document.getElementById("cart-box").style.width = "385px";
    document.getElementById("cart-box").style.display = "block";
  } else {
 
    document.getElementById("cart-box").style.width = "750px";
    document.getElementById("cart-box").style.display = "block";
  }
}else{
  document.getElementById("cart-box").style.width = "0px";
}
    // const mobileScreenWidth = 767;
    // setTimeout(function() {
    //   if (cartToggle || cartToggle === "false") {
    //     localStorage.setItem("cart-toggal", true);
    //     if (window.innerWidth <= mobileScreenWidth) {
    //       document.getElementById("cart-box").style.width = "385px";
    //     } else {
    //       document.getElementById("cart-box").style.width = "750px";
    //     }
    //     SetcartToggle(false);
    //   } else {
    //     localStorage.setItem("cart-toggal", false);
    //     document.getElementById("cart-box").style.display = "none";

    //     SetcartToggle(true);
    //   }
    // }, 200);

    // if (cartToggle) {
    //   document.getElementById("cart-box").style.display = "block";
    // } else {
    //   document.getElementById("cart-box").style.width = "0px";
    // }
  };





  const handlerSideMenu = () => {
    if (mobileMenuToggle) {
      document.getElementById("leftMobileMenu").style.visibility = "visible";
      document.getElementById("leftMobileMenu").style.opacity = "1";

      document.getElementById("rightMobileMenu").style.visibility = "hidden !important";
      document.getElementById("rightMobileMenu").style.opacity = "0 ";

      SetMobileMenuToggle(false);
    }
    if (!mobileMenuToggle) {
      document.getElementById("leftMobileMenu").style.visibility = "hidden";
      document.getElementById("leftMobileMenu").style.opacity = "0";
      SetMobileMenuToggle(true);
    }
  };
  
  document.addEventListener('click', handlerClick);
  return (
    <>
 
        {/* // mobileHeader */}
        <Row className="main-header mobileScreen">
          <Col className="main-headerLeft">
            {" "}
            <img
              className="menuIcon"
              onClick={handlerSideMenu}
              src={menuIcon}
            />{" "}
          </Col>

          <NavLink to="/home">
            <img className="logo" src={logo} />{" "}
          </NavLink>

          <div className="searchForm" id="not-hide">
            <input
              type="text"
              placeholder="Search"
              id="not-hide"
              onChange={handlerSearchPopup}
              name="searchForm"
            />
          <div id="search-hide"  >
            {searchPopup ? (
              <div className="Search_result-content" id="not-hide">
                {results.length >= 1 ? (
                  results.map((item) => {
                    return (
                      <>
                        <NavLink
                          to={
                            item.type === "page"
                              ? `/${item.slug}`
                              : item.name === "Play Product" ? `/play/` : `/scarves/` + item.id
                          }
                          onClick={handlerClick}
                        >
                          <div
                            className="row"
                            style={{
                              borderBottom: "solid 1px",
                              padding: "10px",
                            }}
                          >
                            <div className="col-md-2">
                              <img
                                src={item.featuredImg}
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  objectFit: "cover",
                                }}
                              />
                            </div>
                            <div className="col-md-6">
                              <h6>{item.name}</h6>
                              <small>{item.type}</small>
                            </div>
                          </div>
                        </NavLink>
                      </>
                    );
                  })
                ) : (
                  <>
                    <p className="not-found" id="not-hide">Result Not Found</p>
                  </>
                )}
              </div>
            ) : (
              ""
            )}
</div>
            <NavLink className="cart-hyper" onClick={cartHandler}>
              {" "}
              <img className="cart-icon" id="cart-icon" src={cartIcon} />{" "}
            </NavLink>
            {!isLogin() ? (
              <NavLink to="/login" className="cart-hyper">
                <img className="cart-icon accountIcon" src={accountIcon} />
              </NavLink>
            ) : (
              <NavLink to="/my-account" className="cart-hyper">
                <img className="cart-icon accountIcon" src={accountIcon} />
              </NavLink>
            )}
          </div>
       
        </Row>
  
        {/* // DesktopHeader */}
        <Row className="main-header desktopScreen">
          <Col className="main-headerLeft">
            {" "}
            <img
              className="menuIcon"
              onMouseEnter={shoot}
              src={menuIcon}
            />{" "}
          </Col>

          <Col className="headerCenter">
            <NavLink to="/home">
              <img className="logo" src={logo} />{" "}
            </NavLink>

            
          </Col>

          <Col className="main-headerRight">

          <div className="searchForm"   id="not-hide">
              <input
                type="text"
                id="not-hide"
                placeholder="Search"
                onChange={handlerSearchPopup}
                name="searchform"
              />
              
  <div id="search-hide">
              {searchPopup ? (
                <div className="Search_result-content">
                   
                  {results.length >= 1 ? (
                    results.map((item) => {
                      return (
                        <>
                          <NavLink
                            to={
                              item.type === "page"
                                ? `/${item.slug}`
                                : item.name === "Play Product" ? `/play/` : `/scarves/` + item.id
                            }
                            onClick={handlerClick}
                          >
                            <div
                              className="row"
                              style={{
                                borderBottom: "solid 1px",
                                padding: "10px",
                              }}
                            >
                              <div className="col-md-2" style={{paddingRight:"10px"}} id="not-hide">
                                <img
                                  src={item.featuredImg}
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    objectFit: "cover",
                                  }}
                                />
                              </div>
                              <div className="col-md-6">
                                <h6>{item.name}</h6>
                                <small>{item.type}</small>
                              </div>
                            </div>
                          </NavLink>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <p className="not-found" id="not-hide">Result Not Found</p>
                    </>
                  )}
                </div>
              ) : (
                ""
              )}
</div>
              <NavLink className="cart-hyper" onClick={cartHandler}>
                {" "}
                <img className="cart-icon" id="cart-icon" src={cartIcon} />{" "}
              </NavLink>
              {!isLogin() ? (
                <NavLink to="/login" className="cart-hyper">
                  <img className="cart-icon" src={accountIcon} />
                </NavLink>
              ) : (
                <NavLink to="/my-account" className="cart-hyper">
                  <img className="cart-icon" src={accountIcon} />
                </NavLink>
              )}


            </div>

            <img className="menuIcon" onMouseEnter={shoot} src={menuIcon} />{" "}
          </Col>
        

    
          <div className="overlay" id="myNav" onMouseLeave={shoot}>
            <a href="#" className="closebtn" onClick={closeMenu}>
              &times;
            </a>

            <div className="overlay-content menuitem">
              <Menu />
            </div>
          </div>

     

        </Row>
 

      <CartSliding itemsData={cartDataItems} />
    </>
  );
};

export default Header; // Exporting the Header component as the default export
