import React from "react";
import Footer from "../footer/footer";  
import Header from "../header/header";    

function Layout(props) {
  // Defining a function component called Layout that takes in props as an argument
  const { childCom } = props;  
  return (
    // Returning some JSX markup
    <>
        <div id="page-container">

      <Header />  
       {childCom} 
      <Footer />  
      </div>
    </>
  );
}

export default Layout;  
