import React, { useEffect, useState } from "react";
 
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteToCart,
  getPaymentIntent,
  UpdatePaymentIntent,
  updateToCart,
} from "../../axiosCalls/pages";
import { setAddToCart } from "../../redux/actions/pagesActions";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { toast } from "react-toastify";

const CartSliding = (props) => {
  const navigate = useNavigate();
  const itemsData = props;
 
  const [cartData, setCartData] = useState(itemsData.itemsData);
  const [reloadObj, setReloadObj] = useState(false);
 

 const handleClick = () =>{
  
  setCartData(itemsData.itemsData)
}
     
  document.addEventListener('click', handleClick);


  const dispatch = useDispatch();
  const [cartToggle, SetcartToggle] = useState(itemsData.itemsData.item_count);
  const [loading, setLoading] = useState(false);

  if (cartToggle !== itemsData.itemsData.item_count) {
    setCartData(itemsData.itemsData);
    SetcartToggle(itemsData.itemsData.item_count);
   }
  const cartHandler = async () => {
    localStorage.setItem("cart-toggal", false);
    document.getElementById("cart-box").style.width = "0px";
    document.getElementById("cart-box").style.display = "none";
  }
  const cartprodccesHandler = async () => {
    setLoading(true)
    const cartDataItems = JSON.parse(localStorage.getItem("cart_items"));
     if(localStorage.getItem("payment_intent") === 'false' || localStorage.getItem("payment_intent") === false  || !localStorage.getItem("payment_intent")){
      const resPayment = await getPaymentIntent(cartDataItems.totals.total);  
      localStorage.setItem("client_secret", resPayment.data.data.client_secret);
   localStorage.setItem("client_secret_ID", resPayment.data.data.id);
   localStorage.setItem("payment_intent",true)

   }else{
     let price  = cartDataItems.totals.total;
     if(price <=0 || !price || price === null){
       price = 1;
     }
     
     await UpdatePaymentIntent(cartDataItems.totals.total, localStorage.getItem("client_secret_ID")  )
     
   }
    setLoading(false)
    localStorage.setItem("cart-toggal", false);
    document.getElementById("cart-box").style.width = "0px";
    document.getElementById("cart-box").style.display = "none";
    navigate('/checkout');



  };
  return (
    <>
      <div className="shopping-cart" key="001" id="cart-box">
        {loading ? (
          <>
            <div className="overlay-loader" key="005">
              {" "}
            </div>
            <div className="spinner" key="006"></div>
          </>
        ) : (
          ""
        )}

        <div className="title" id="cart-icon" key="002">
          Shopping Bag
          <a   className="" id="cart-icon" onClick={cartHandler}>
            <span className="delete-btn" id="cart-icon"></span>
          </a>
        </div>

        <div className="cart-items" id="cart-icon" key="003">
          {/*  item  */}

          {cartData.items.map((item, index) => {
            // item.item_key && ();

            const handleIncrement = async () => {
              setLoading(true);
              // Get the current quantity value
              const currentQuantity = item.quantity.value;

              // Update the quantity by incrementing it
              const updatedQuantity = currentQuantity + 1;
              
              // window.location.reload(true);
              if (updatedQuantity > 5 || cartData.item_count > 4) {
                // alert("Please contact sales@leskoy.com if you would like to order more than 5 pieces");
                // NotificationManager.success("Please contact sales@leskoy.com if you would like to order more than 5 pieces","",5000)
                toast.info(
                  "Please contact sales@leskoy.com if you would like to order more than 5 pieces"
                );

                setLoading(false);
              } else {
                const updatedItems = [...cartData.items];
                const oldqty = updatedItems[index].quantity.value;
                updatedItems[index].quantity.value = updatedQuantity;
               
                const OldcartData = JSON.parse(localStorage.getItem('cart_items'));

                OldcartData.items[index].quantity.value = updatedQuantity;               
                
                OldcartData.totals.subtotal = (parseFloat(OldcartData.items[index].price*updatedQuantity) + parseFloat(OldcartData.totals.subtotal) - parseFloat(oldqty * OldcartData.items[index].price)).toFixed(2);
                OldcartData.item_count = parseFloat(OldcartData.item_count)+1
              
                OldcartData.items[index].totals.subtotal = (OldcartData.items[index].price*updatedQuantity).toFixed(2)
               
                OldcartData.items[index].totals.total = (OldcartData.items[index].price*updatedQuantity).toFixed(2)
                
                OldcartData.totals.total =  (parseFloat(OldcartData.totals.subtotal )+ parseFloat( OldcartData.totals.fee_total)).toFixed(2) ;
                // Update other properties as needed
                
               
               
                // const res = await updateToCart(updatedQuantity, item.item_key);

                setCartData(OldcartData);
                dispatch(setAddToCart(OldcartData));
                localStorage.setItem("cart_items", JSON.stringify(OldcartData));

                // await UpdatePaymentIntent(
                //   res.data.totals.total,
                //   localStorage.getItem("client_secret_ID")
                // );

                setLoading(false);
              }
            };
     
     
            const handleDecrement = async () => {
              setLoading(true);
              // Get the current quantity value
              const currentQuantity = item.quantity.value;

              // Ensure the quantity is greater than 0 before decrementing
              if (currentQuantity > 0) {
                // Update the quantity by decrementing it
                const updatedQuantity = currentQuantity - 1;
                const updatedItems = [...cartData.items];
                updatedItems[index].quantity.value = updatedQuantity;


                const OldcartData = JSON.parse(localStorage.getItem('cart_items'));
                OldcartData.items[index].quantity.value = updatedQuantity;            
                OldcartData.item_count = OldcartData.item_count-1

                let subtotalSum = 0;

                OldcartData.items.forEach(item => {
                  
                  subtotalSum += parseFloat(item.totals.subtotal);
                });
                
                

                OldcartData.totals.subtotal = parseFloat(subtotalSum-OldcartData.items[index].price).toFixed(2);
          
               
                OldcartData.items[index].totals.subtotal = (OldcartData.items[index].price*updatedQuantity)
               
                OldcartData.items[index].totals.total = (OldcartData.items[index].price*updatedQuantity)
                
                OldcartData.totals.total =  (parseFloat(OldcartData.totals.subtotal ) + parseFloat( OldcartData.totals.fee_total) ).toFixed(2);

                // const res = await updateToCart(updatedQuantity, item.item_key);

                dispatch(setAddToCart(OldcartData));
                setCartData(OldcartData);
                localStorage.setItem("cart_items", JSON.stringify(OldcartData));
               
              
              }
              setLoading(false);
            };
         
         
            const handlerDeleteItem = async () => {

              // Retrieve cart data from local storage
  const cartItemsData = JSON.parse(localStorage.getItem('cart_items'));

  // Get the quantity of the item being removed
  const removedItemQuantity = cartItemsData.items[index].quantity.value;

    // Remove the item from the cart items array
    cartItemsData.items.splice(index, 1);
    cartItemsData.item_count -= removedItemQuantity;
   
     cartItemsData.totals.subtotal = cartItemsData.items.reduce(
      (sum, item) => sum + parseFloat(item.totals.subtotal),
      0
    ).toFixed(2);
    
    if(cartItemsData.item_count === 0){

      cartItemsData.fees = []
      cartItemsData.totals.total = (
        parseFloat(cartItemsData.totals.subtotal) 
      ).toFixed(2);
    }else{
       cartItemsData.totals.total = (
      parseFloat(cartItemsData.totals.subtotal) +
      parseFloat(cartItemsData.totals.fee_total)
    ).toFixed(2);
    }
   

    // Save the updated cart data back to local storage
    localStorage.setItem('cart_items', JSON.stringify(cartItemsData));
    dispatch(setAddToCart(cartItemsData));
                setCartData(cartItemsData);





              // setLoading(true);
              // const item_key = item.item_key;
              // const res = await deleteToCart(item_key);
              // dispatch(setAddToCart(res.data));
              // localStorage.setItem("cart_items", JSON.stringify(res.data));
              // setLoading(false);

              // let price = res.data.totals.total;
              // if (price <= 0 || !price || price === null) {
              //   price = 1;
              // }
              // // await UpdatePaymentIntent(
              // //   price,
              // //   localStorage.getItem("client_secret_ID")
              // // );





            };

            return (
              <>
                {item.item_key && (
                  <div className="item" id="cart-icon" key={index + 99}>
                    <div className="buttons" id="cart-icon">
                      <span
                        id="cart-icon"
                        className="delete-btn"
                        onClick={handlerDeleteItem}
                        key={item.item_key + 1}
                      ></span>
                      <span className="like-btn"></span>
                    </div>

                    <div className="image" id="cart-icon">
                      <img src={item.featured_image} alt="" id="cart-icon" />
                    </div>

                    <div className="description" id="cart-icon">
                      <span id="cart-icon"> {item.name}</span>

                      {/* <span>
                        <img
                          src={img1}
                          key="scarf"
                          style={{ width: "20px", marginRight: "10px" }}
                        />
                        <img
                          src={img2}
                          key="stripe"
                          style={{ width: "20px" }}
                        />
                      </span> */}
                      <span id="cart-icon">160x80 cm</span>
                    </div>

                    <div className="quantity" id="cart-icon">
                      {item.quantity.value !== 1 ? (
                        <button
                          className="minus-btn"
                          onClick={handleDecrement}
                          type="button"
                          id="cart-icon"
                          name="button"
                        >
                          <img
                            id="cart-icon"
                            src="https://designmodo.com/demo/shopping-cart/minus.svg"
                            alt=""
                          />
                        </button>
                      ) : (
                        <button
                          className="minus-btn"
                          type="button"
                          id="cart-icon"
                          name="button"
                          style={{ cursor: "no-drop" }}
                        >
                          <img
                            id="cart-icon"
                            src="https://designmodo.com/demo/shopping-cart/minus.svg"
                            alt=""
                          />
                        </button>
                      )}
                      <input
                        type="text"
                        name="qty"
                        id="cart-icon"
                        value={item.quantity.value}
                        readOnly
                      />

                      <button
                        className="plus-btn"
                        onClick={handleIncrement}
                        key={index}
                        id="cart-icon"
                        type="button"
                        name="button"
                      >
                        <img
                          src="https://designmodo.com/demo/shopping-cart/plus.svg"
                          id="cart-icon"
                          alt=""
                        />
                      </button>
                    </div>

                    <div className="total-price" id="cart-icon">
                      {cartData.currency.currency_symbol} {item.totals.total}
                    </div>
                  </div>
                )}

                <div className="cart-extra-address-items-qty" key="id-004">
                  {item.cart_item_data.giftedArray ? (
                    item.cart_item_data.giftedArray[0].isGifted ? (
                      <>
                        <span>
                          <strong>Gifts</strong>
                        </span>

                        {item.cart_item_data.giftedArray.map((gift) => {
                          return (
                            <>
                              <div className="extra-address-items">
                                {" "}
                                <span className="">{gift.address}</span>
                                <span className="extra-address-items-qty">
                                  {gift.qty} qty
                                </span>
                              </div>
                            </>
                          );
                        })}
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>
              </>
            );
          })}
        </div>
        {!cartData.items[0] ? (
          <h4 style={{ textAlign: "center", marginTop: "-5%" }}>
            No Item in Cart
          </h4>
        ) : (
          <div className="procced-tocheckout">
            <NavLink
              className="checkout-btn"
              id="cart-icon"
              onClick={cartprodccesHandler}
            >
              Proceed to Checkout
            </NavLink>
          </div>
        )}
        <div className="bottom-cart" id="cart-icon" key="004">
          <div className="sub-total" id="cart-icon">
            <h4 id="cart-icon">
              Subtotal{" "}
              <span className="subtotal-price" id="cart-icon">
                {cartData.currency.currency_symbol} {cartData.totals.subtotal}{" "}
              </span>
            </h4>
          </div>
          {cartData.fees["shipping-and-handling"] ? (
            <div className="sub-total" id="cart-icon">
              <h4 id="cart-icon">
                {cartData.fees["shipping-and-handling"].name}{" "}
                <span className="subtotal-price" id="cart-icon">
                  {cartData.currency.currency_symbol}{" "}
                  {cartData.fees["shipping-and-handling"].fee}{" "}
                </span>
              </h4>
            </div>
          ) : (
            ""
          )}
          <div className="sub-total" id="cart-icon">
            <h4 id="cart-icon">
              Total{" "}
              <span className="subtotal-price" id="cart-icon">
                {cartData.currency.currency_symbol} {cartData.totals.total}{" "}
              </span>
            </h4>
          </div>
        </div>
      </div>
      <NotificationContainer />
    </>
  );
};

export default CartSliding;
