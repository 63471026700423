import React , {useState} from "react";
import { Button, Form } from "react-bootstrap";
import logo from "../assets/images/logo.png";
import { NavLink, useNavigate } from "react-router-dom";
import { ValidateResetPasswordApi } from "../axiosCalls/auth";
import NotificationContainer from "react-notifications/lib/NotificationContainer";
import NotificationManager from "react-notifications/lib/NotificationManager";

const VerficationOtp = () => {
    
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formValue, setFormValue] = useState({
    code: ""
  });
  const [errors, setErrors] = useState({});
  const handleOnchange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
    
  };
  const submitHandler = async () => {
    const { code } = formValue;
    let errors = {};
     if (!code) {
      errors["code"] = "Code is required";
      setErrors(errors);
    } else if (Object.keys(errors).length <= 0) {
      errors["code"] = "";
       setErrors(errors); 
       setLoading(true)
        const email = localStorage.getItem('email-otp');
       
        await  ValidateResetPasswordApi(code,email).then((res)=>{
            // localStorage.setItem('token',res.data.data.token);
            localStorage.setItem('code-otp',code);
            setLoading(false)
            NotificationManager.success(
           res.data.message,"",1000
            );
            setTimeout(function() {
            navigate('/update-password');
          }, 3000);

         }).catch((error)=>{
          setLoading(false)
         })


    } else {
      setErrors(errors);
    }
  };
  onkeydown = (e) => {
    if (e.keyCode === 13) {
    submitHandler();
    }
  };
    return (
        <>
         {loading &&
    <>
    <div className="myaccount-loader"> 
    <div className="overlay-loader" key="005"> </div>
    <div className="spinner" key="006"></div>
    </div>
  </>
    }
            <div className="center forgot-page">
            <NavLink to='/home'>
        <img className="logo" src={logo} />
      </NavLink>      </div>

      <div className="center-form slide-in">
        <h1 className="main-heading">Reset Password</h1>
        <p className="sub-heading">
        A 4 digit code has been sent to your associated Email.
        </p>

 
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Code</Form.Label>
            <Form.Control type="number" name="code" placeholder="Enter Code" onChange={handleOnchange} />
            <Form.Text className="text-muted">
              {errors.code && <p className="errorText">{errors.code}</p>}
            </Form.Text>
          </Form.Group>
          <Button className="submit-button" type="button" onClick={submitHandler}>
            Send
          </Button>

          <NavLink
            className="login-here center"
            style={{ marginTop: "20px" }}
            to="/login"
          >
            Back To Login
          </NavLink>
   
      </div>
      <NotificationContainer />
        </>
    );
}

export default VerficationOtp;
