import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import leftlogo from "../../assets/images/logoLeftImg.png";
import HeaderLanding from "../../components/header/header-landing";
import FooterLanding from "../../components/footer/footer-landing";
import { NavLink, useNavigate } from "react-router-dom";
import { isLogin } from "../includes/utils";

const AuthPages = (formLayout) => {
  const { childCom } = formLayout;
      const navigate = useNavigate();
  if(isLogin()){
    setTimeout((function(){
      navigate('/home');
     }),2)  }  

  return (
    <>
      <Row className="auth-page">
        <Col md="6" className="auth-left-col">
        <NavLink to="/"><img src={leftlogo} className="left-logo" /></NavLink>
        </Col>
        <Col   md="6" className="auth-right-col">
          <HeaderLanding />

          <Container className="form-area">{childCom}</Container>
          <FooterLanding />
        </Col>
      </Row>
    </>
  );
};

export default AuthPages;
