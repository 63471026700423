import React, { useState, useEffect, useRef, useContext } from "react";
import SidebarMenu from "../components/header/sidebar-menu";
import Carousel from "react-grid-carousel";
import ProductImg from "../assets/images/products/main-img.png";
import leftArrow from "../assets/images/icons/left-arrow.svg";
import plusIcon from "../assets/images/icons/plus-icon.png";
import logo from "../assets/images/logo.png";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, addToCartFee, getCartApi, getPaymentIntent, RemoveAddToCartFee, UpdatePaymentIntent } from "../axiosCalls/pages";
import { setAddToCart } from "../redux/actions/pagesActions";
import { toast } from "react-toastify";

const Play = () => { 
   const navigate = useNavigate();

  const { id } = useParams();
  const dispatch = useDispatch();
  const getProduct = useSelector((state) => state);
  const product = getProduct.product.product;
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const [Gifted, setGifted] = useState([false])
  const [GiftErrors,setGiftErrors] = useState(false)
  const [fields, setFields] = useState([{isGifted: false, qty: "0", address: "" }]);
  const [email,setEmail] = useState(null);
  const [selectedImg, setSelectedImg] = useState(ProductImg);
  const [activeScarfImageKey, setActiveScarfImageKey] = useState('scarfs1');
  const [activeStripeImageKey, setActiveStripeImageKey] = useState('stripes1');

  // if(!product.id && !loading){
  //   setLoading(true);
  // }else if(product.id && loading){
  //   setLoading(false)
  // }
  const [selectedVari, setselectedVari] = useState({
    scarfs: "scarfs1",
    stripes: "stripes1",
  });
  const validateEmail = (value) => {
    // Regular expression for email validation
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(value);
  };
  const [sumOfQty , setSumOfQty] = useState(5);

// console.log(product)
  const handlerselectedImg = (e) => {
    const value = e.target.alt;
    const type = e.target.dataset.type;

    // console.log(selectedIndex)
    setselectedVari((prevState) => {
      if (type === "scarfs") {
        return { ...prevState, scarfs: value };
      } else if (type === "stripes") {
        return { ...prevState, stripes: value };
      } else {
        return prevState; // If the type doesn't match 'scarfs' or 'stripes', return the previous state
      }
    });
    if (type === "scarfs") {
      setActiveScarfImageKey(value);
    } else if (type === "stripes") {
      setActiveStripeImageKey(value);
    }

    // e.stopPropagation()
  };
  const selectedIndex = product.variations.findIndex(
    (item) =>
      item.attributes.attribute_pa_scarfs_design === selectedVari.scarfs &&
      item.attributes.attribute_pa_stripes_design === selectedVari.stripes
  );
   const handleAddField = () => {
    const values = [...fields];
    values.push({isGifted: false, qty: "0", address: ""  });
    setFields(values);
  };
  const handleRemoveField = (index) => {
    const values = [...fields];
    values.splice(index, 1);
    setFields(values);
  };

  // Calculate the sum of qty values where isGifted is true

let qtySum = fields.reduce((sum, field,index) => {
 
  // if(index === 0){
  //   return sum + parseInt(field.qty);
  // }
 
  if (field.isGifted === "true") {
    return sum + parseInt(field.qty);
  } 
 
  return sum
 }, 0);



 if(qtySum > 4 ) {
  // alert('More than 5 Quantity You need to Contact via Email');
  // qtySum = 5;
 }else{
  
 }
  if (!qtySum) qtySum = 1;
 
 

  const cartData = useSelector((state) => state).addtocart.addtocart;

  const handlerAddToCart = async () => {
   const emailcheck = validateEmail(email);

   fields.map((item,index)=>{
     if (item.isGifted === "true" && (item.qty === "0" || !item.address)) {
     setGiftErrors(true)
    } 
  })
     if (!selectedVari.stripes || !selectedVari.scarfs) {
      toast.error("Please Select Variations");
     
    } 
    else if(qtySum <= 0){
      toast.error("Choose Quantity at least 1");
    }
    
    // else if(!email){
    //   toast.info("Please Enter your email");

    // }else if(!emailcheck){
    //   toast.info("Please Enter a vaild email");

    // }
    
    else if(qtySum > 5){
      toast.info("Please contact sales@leskoy.com if you would like to order more than 5 pieces")
    } else if(GiftErrors){
      toast.error("Please provide a valid quantity and address.");
      setGiftErrors(false)
    }
    else {
      if(cartData.item_count >= 5 ){
        // alert("Please contact sales@leskoy.com if you would like to order more than 5 pieces");
        // NotificationManager.success("Please contact sales@leskoy.com if you would like to order more than 5 pieces","",5000)
        toast.info("Please contact sales@leskoy.com if you would like to order more than 5 pieces")

        setLoading(false);
      }else{

      
      setLoading(true);
      const cartKey = localStorage.getItem("cart_key");

       let sendToCart = JSON.stringify({
        "id": `${product.id}`,
        "quantity": `${qtySum}`,
        "variation": {
          attribute_pa_stripes_design: selectedVari.stripes,
          attribute_pa_scarfs_design: selectedVari.scarfs,
        },
        "cart_key":cartKey,
        "item_data": {
          "text_on_image" : text,
          "giftedArray" : fields
        }
      });   
     
      let res = await addToCart(sendToCart);
      // localStorage.getItem("cart_key");
      const { fees } = res.data;

      if (Object.keys(fees).length === 0) { 
         const res2 = await addToCartFee("30");
      }
      const cartGet = await getCartApi(cartKey);
         
      localStorage.setItem("cart_items",JSON.stringify(cartGet.data))
      // localStorage.setItem("cart_user_email",email)
      // localStorage.setItem("text_on_image",text)
      // localStorage.setItem("giftedArray", JSON.stringify(fields));
      dispatch(setAddToCart(cartGet.data));

       
        setLoading(false);
      //   if(localStorage.getItem("payment_intent") === 'false' || localStorage.getItem("payment_intent") === false  || !localStorage.getItem("payment_intent")){
      //     const resPayment = await getPaymentIntent(cartGet.data.totals.total);  
      //     localStorage.setItem("client_secret", resPayment.data.data.client_secret);
      //  localStorage.setItem("client_secret_ID", resPayment.data.data.id);
      //  localStorage.setItem("payment_intent",true)
   
      //  }else{
      //    let price  = res.data.totals.total;
      //    if(price <=0 || !price || price === null){
      //      price = 1;
      //    }
         
      //    await UpdatePaymentIntent(cartGet.data.totals.total, localStorage.getItem("client_secret_ID")  )
      //  }
      
      
     
   
      
       const mobileScreenWidth = 767;
   
       if (window.innerWidth <= mobileScreenWidth) {
         document.getElementById("cart-box").style.width = "385px";
         document.getElementById("cart-box").style.display = "block";
         localStorage.setItem("cart-toggal", true);
       } else {
         document.getElementById("cart-box").style.width = "750px";
         document.getElementById("cart-box").style.display = "block";
         localStorage.setItem("cart-toggal", true);
       }
     

      }
    
    }
  };

  return (
    <>
      {loading ? (
        <>
          <div className="loader-cart">
            <div className="overlay-loader"></div>
            <div className="loader-container">
              <div className="spinner"></div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      <SidebarMenu menuType="left" />
      <SidebarMenu menuType="right" />

      <div className="row play-page top-border">
        <div className="col-md-6 leftSide-center-text">
          <div className="row">
          {/* <div className="col-md-1"></div> */}
            <div className="col-md-3 text-made-you">

              <div className="playpage-heading">
              <h1 className="">made for you</h1>
            </div>


            </div>
            <div className="col-md-9">
  <div className="product-single-img">
               <img
                src={
                  selectedVari.scarfs ? selectedVari.stripes
                    ? product.variations[selectedIndex].image
                    : product.featuredimg : product.featuredimg
                }
                className="product-play-img img-fluid"
              />
          
              <div className="parent-text-on-imag">
                      <div className="text-on-image">{text}</div>
              </div>

            
            </div>
            </div>
          </div>


          <div className="playpage-center-text">

            

          

            {/* <div className="footer-centerPart">
              <p className="">Be less coy</p>
            </div> */}
          </div>
        </div>
       
 
        <div className="col-md-6 inner-page slide-in">
          <h1 className="heading ">ready to play?</h1>
          <div className="price">
            <span>
              ${" "}
              {selectedVari.scarfs && selectedVari.stripes
                ? product.variations[selectedIndex].price
                : product.price}
              .00
            </span>
          </div>
          <div className="leftSide-center-text right-side">
            {/*  */}

            {Object.keys(product.attribute_images).map((category) => (
              <>
                <h3 className="attribute-heading" key={`headings-${category}`} >
                  Choose your {category === 'scarfs' ? 'scarf' : 'stripe' } design
                </h3>
                <div className="attribute-main" key={category} id={category}>
                  <Carousel
                    key={category}
                    arrowLeft={<img src={leftArrow} className="arrow-left" />}
                    arrowRight={<img src={leftArrow} className="arrow-right" />}
                    cols={3}
                    rows={1}
                    gap={1}
                    responsiveLayout={[
                      
                      {
                        breakpoint: 767,
                        cols:5
                     
                      },
                    ]}
                    // mobileBreakpoint={767}
                  >
                    {Object.keys(product.attribute_images[category]).map(
                      (imageKey) => (
                        <Carousel.Item key={`carousel-${imageKey}`}>
                          <img
                            key={imageKey}
                            onClick={handlerselectedImg}
                            data-type={category}
                            src={product.attribute_images[category][imageKey]}
                            alt={imageKey}
                            className={`image playattribute ${
                              category === "scarfs" &&
                              activeScarfImageKey === imageKey
                                ? "activeScarfAttribute"
                                : ""
                            } ${
                              category === "stripes" &&
                              activeStripeImageKey === imageKey
                                ? "activeStripeAttribute"
                                : ""
                            }`}
                          />
                        </Carousel.Item>
                      )
                    )}
                  </Carousel>
                </div>
              </>
            ))}

            <div className="play-name-title">
              <input
                type="text"
                placeholder=" Your scarf statement"
                value={text}
                maxLength={75}
                onChange={(event) => setText(event.target.value)}
              />
            </div>

            {/* <div className="play-email">
              <input type="email" value={email} placeholder="E-mail" onChange={(e)=>{setEmail(e.target.value)}} />
             </div> */}

            <div className="play-gift-box"></div>

            <div className="play-qty"></div>
            {/* {fields.map((field, index) => (
              <div key={index}>
                <div className="checkbox-wrapper-37">
                  <input
                    type="checkbox"
                    name="checkbox"
                    id={"checkbox-wrapper-" + index}
                    value={Gifted[index]}
                    onChange={(e) => {
                      let newValue =e.target.value;
                      newValue === 'true' ? newValue = false : newValue='true'
                      Gifted[index] = newValue
                      const values = [...fields];
                      values[index].isGifted = newValue;
                      setFields(values);
                      setGifted(Gifted)
                      
                    }} 
                  />
                  <label
                    htmlFor={"checkbox-wrapper-" + index}
                    className="terms-label"
                  >
                    <svg
                      className="checkbox-svg"
                      viewBox="0 0 200 200"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask id="path-1-inside-1_476_5-37" fill="white">
                        <rect width="200" height="200" />
                      </mask>
                      <rect
                        width="200"
                        height="200"
                        className="checkbox-box"
                        strokeWidth="40"
                        mask="url(#path-1-inside-1_476_5-37)"
                      />
                      <path
                        className="checkbox-tick"
                        d="M52 111.018L76.9867 136L149 64"
                        strokeWidth="15"
                      />
                    </svg>
                    <span className="label-text">Gift</span>
                  </label>
                  <input
                    type="number"
                    className="input-felid qty"
                    placeholder=""
                    min={1}
                    max={sumOfQty}
                    readOnly={fields[index].isGifted == 'true'  ? false : true}
                    onChange={(e) => {
                      const values = [...fields];
                      values[index].qty = e.target.value;
                      setFields(values);
                    
                 if(qtySum > 5){
                    setSumOfQty(0)
                 }else{
                  setSumOfQty(5)
                 }
                      
                    }} />
                  <label className="qty-text">Quantity</label>
                   <input
                    type="text"
                    className="input-felid address"
                     readOnly={fields[index].isGifted == 'true' ? false  : true}
                     
                    onChange={(e) => {
                      const values = [...fields];
                      values[index].address = e.target.value;
                      setFields(values);
                    
                    }}
                    placeholder={"Enter Address for gift"}
                  />
                  {index !== 0 && (
                    <button
                      className="remove-input"
                      onClick={() => handleRemoveField(index)}
                    >
                      <img src={plusIcon} className="corss-icon-plus" />
                    </button>
                  )}{" "}
                </div>
              </div>
            ))}
            {fields.length < 5  && qtySum <5? 
            <button
              className="ship-to-address"
              onClick={() => handleAddField()}
            >
              <img src={plusIcon} className="ship-icon-plus" /> Ship To Another
              Address
            </button>
            :''
} */}
            <div className="add-to-cart-area">
              <img src={logo} className="add-cart-logo" />
              <button onClick={handlerAddToCart} className="add-to-cart-btn">
                Add To Cart
              </button>
            </div>
          </div>
        </div>


      </div>
    </>
  );
};

export default Play;
