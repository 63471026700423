import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import logo from "../assets/images/logo.png";
import { signUpApi } from "../axiosCalls/auth";
import { NavLink, useNavigate } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import NotificationContainer from "react-notifications/lib/NotificationContainer";



const Signup = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formValue, setFormValue] = useState({
    email: "",
    password: "",
    confrimPassword: "",
    firstName: "",
    lastName: "",
  });

  const [errors, setErrors] = useState({});
  const onChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  const validateEmail = (value) => {
    // Regular expression for email validation
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(value);
  };
  const submitHandler = async () => {

    const { email, password, confrimPassword, firstName, lastName } = formValue;
    const emailcheck = validateEmail(email);

    let errors = {};
     if (!firstName) {
      errors["firstName"] = "First Name is required";
    } else if (!lastName) {
      errors["lastName"] = "Last Name is required";
    }
    else if (!email) {
      errors["email"] = "Email is required";
    }else if(!emailcheck){
      errors["email"] = "Email is not vaild email";
    }else if (!password) {
      errors["password"] = "Password is required";
    } else if (password.includes(' ')) {
      errors["password"] = "Password cannot contain spaces";
    }else if (!confrimPassword) {
      errors["confrimPassword"] = "Confirm Password is required";
    } else if (password && confrimPassword) {
      if (!password.match(confrimPassword)) {
        errors["confrimPassword"] =
          "Confirm password and password doesn't match ";
      }
    } 
    if (Object.keys(errors).length <= 0) {
      setLoading(true)
      errors["confrimPassword"] = "";
      errors["password"] = "";
      errors["password"] = "";
      setErrors(errors);
      await signUpApi(email, password, firstName, lastName).then((res) => {
        setLoading(false)

         NotificationManager.success(
          "Sign Up Successfully!","",1000
        );
        
        setTimeout(function() {
          navigate("/login");
        }, 3000);
        
        // errors["error"] = res;

      }).catch((error)=>{
        setLoading(false)
      });
    } else {
      setErrors(errors);
    }
  };
  onkeydown = (e) => {
    if (e.keyCode === 13) {
    submitHandler();
    }
  };
  return (
    <>
     {loading &&
    <>
    <div className="myaccount-loader"> 
    <div className="overlay-loader" key="005"> </div>
    <div className="spinner" key="006"></div>
    </div>
  </>
    }
      <div className="center signup">
      <NavLink to='/home'>
        <img className="logo" src={logo} />
      </NavLink>      </div>

      <div className="center-form slide-in">
        <p className="sub-heading">Welcome</p>
        <h1 className="main-heading">Signup to your account</h1>
        <Form.Text className="text-muted center">
          {/* {errors.error && <p className="errorText">{errors.error}</p>} */}
        </Form.Text>
        <Form>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  onChange={onChange}
                  name="firstName"
                  placeholder="Enter Your First Name"
                />
 <Form.Text className="text-muted">
                  {errors.firstName && (
                    <p className="errorText">{errors.firstName}</p>
                  )}
                </Form.Text>

              </Form.Group>

            </Col>
            <Col className="ml-1">
              <Form.Group className="mb-3" controlId="formBasicEmail">
               
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  onChange={onChange}
                  name="lastName"
                  placeholder="Enter Your Last Name"
                />

                <Form.Text className="text-muted">
                  {errors.lastName && (
                    <p className="errorText">{errors.lastName}</p>
                  )}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              onChange={onChange}
              name="email"
              placeholder="Enter email"
            />
            <Form.Text className="text-muted">
              {errors.email && <p className="errorText">{errors.email}</p>}
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              onChange={onChange}
              name="password"
              placeholder="Password"
            />
            <Form.Text className="text-muted">
              {errors.password && (
                <p className="errorText">{errors.password}</p>
              )}
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicConfirnPassword">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              name="confrimPassword"
              onChange={onChange}
              placeholder="Confirm Password"
            />
            <Form.Text className="text-muted">
              {errors.confrimPassword && (
                <p className="errorText">{errors.confrimPassword}</p>
              )}
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3 " controlId="formBasicCheckbox">
            <Form.Label>
              Already have an Account ?{" "}
              <NavLink className="login-here" to="/login">
                Login here
              </NavLink>
            </Form.Label>
          </Form.Group>

          <Button
            className="submit-button"
            onClick={submitHandler}
            type="button"
          >
            SignUp
          </Button>
        </Form>
      </div>
      <NotificationContainer />
    </>
  );
};

export default Signup;
