import { async } from "q";
import { useDispatch, useSelector } from "react-redux";
import { setPage } from "../redux/actions/pagesActions";
import { axiosInstance } from "./axiosInstance";

var Authentication = {
  auth: {
    username: process.env.REACT_APP_USERNAME_KEY,
    password: process.env.REACT_APP_PASSWORD_KEY,
  },
};

export const allPageApi = async () =>{
  let res;

  try {
    res = await axiosInstance.get(`/wp/v2/pages/`, Authentication);
  } catch (error) {
    console.log("error", error);
  }

  return res ? res : "";
}
export const PageApi = async (pageID) => {
  let res;

  try {
    res = await axiosInstance.get(`/wp/v2/pages/${pageID}`, Authentication);
  } catch (error) {
    console.log("error", error);
  }

  return res ? res : "";
};

// get media images by id
export const getMediaById = async (id) => {
  let res;
  try {
    res = await axiosInstance.get(`/wp/v2/media/${id}`, Authentication);
  } catch (error) {
    console.log(error, "error");
  }
  return res ? res : "";
};

export const productsAllGet = async () => {
  let res;
  try {
    res = await axiosInstance.get(`/wc/v3/products`, Authentication);
  } catch (error) {
    console.log(error, "error");
  }
  return res ? res : "";
};

export const productGet = (id) => {
  let res;
  try {
    res = axiosInstance.get(`/wc/v3/products/${id}`, Authentication);
  } catch (error) {
    console.log(error, "error");
  }
  return res ? res : "";
};

export const productVariationGet = (productID, id) => {
  let res;
  try {
    res = axiosInstance.get(
      `/wc/v3/products/${productID}/variations/${id}`,
      Authentication
    );
  } catch (error) {
    console.log(error, "error");
  }
  return res ? res : "";
};
export const getCartApi = (cart_key) => {
  let res;

  try {
    // cart_key=9e18904482b4faf8762361836a83b93d
    res = axiosInstance.get(`/cocart/v2/cart?cart_key=${cart_key}`);
  } catch (error) {
    console.log(error, "error");
  }
  return res ? res : "";
};

export const addToCart = (cartData) => {
  let res;
  try {
    const cartKey = localStorage.getItem("cart_key");
    res = axiosInstance.post(
      `/cocart/v2/cart/add-item/?cart_key=${cartKey}`,
      cartData
    );
  } catch (error) {
    console.log(error, "error");
  }
  return res ? res : "";
};

export const addToCartFee = (cartData) => {
  let res;
  try {
    let data = JSON.stringify({
      "name": "Shipping and handling",
      "amount": cartData
    });
    const cartKey = localStorage.getItem("cart_key");
    res = axiosInstance.post( `/cocart/v1/fees/?cart_key=${cartKey}`,data);
  } catch (error) {
    console.log(error, "error");
  }
  return res ? res : "";
};

export const RemoveAddToCartFee = () => {
  let res;
  try {
   
    const cartKey = localStorage.getItem("cart_key");
    res = axiosInstance.delete( `/cocart/v1/fees/?cart_key=${cartKey}`);
  } catch (error) {
    console.log(error, "error");
  }
  return res ? res : "";
};



export const deleteToCart = (itemkey) => {
  let res;
  try {
    const cartKey = localStorage.getItem("cart_key");
    res = axiosInstance.delete(
      `/cocart/v2/cart/item/${itemkey}/?cart_key=${cartKey}`
    );
  } catch (error) {
    console.log(error, "error");
  }
  return res ? res : "";
};
export const updateToCart = (qty, itemkey) => {
  let res;
  let data = JSON.stringify({
    quantity: qty,
  });
  try {
    const cartKey = localStorage.getItem("cart_key");
    res = axiosInstance.post(
      `/cocart/v2/cart/item/${itemkey}/?cart_key=${cartKey}`,
      data
    );
  } catch (error) {
    console.log(error, "error");
  }
  return res ? res : "";
};

export const clearAllCart = () => {
  let res;

  try {
    const cartKey = localStorage.getItem("cart_key");
    res = axiosInstance.post(`/cocart/v2/cart/clear/?cart_key=${cartKey}`);
  } catch (error) {
    console.log(error, "error");
  }
  return res ? res : "";
};

export const getPaymentIntent = (price) => {
  let res;
  try {
    let data = JSON.stringify({
      price: price,
    });

    res = axiosInstance.post(
      `/leskoy/v1/stripe-payment-intent/?path=token/validate`,
      data
    );
  } catch (error) {
    console.log(error);
  }
  return res ? res : "";
};

export const UpdatePaymentIntent = (price,id) => {
  let res;
  try {
    let data = JSON.stringify({
      id:id,
      price: price,
    });

    res = axiosInstance.post(
      `/leskoy/v1/stripe-payment-intent-update/?path=token/validate`,
      data
    );
  } catch (error) {
    console.log(error);
  }
  return res ? res : "";
};

export const createOrder = (data) =>{
  let res;
  try {
    res = axiosInstance.post(`wc/v3/orders`,data,Authentication);
  } catch (error) {
    console.log(error);
  }
  return res ? res : "";
}


export const UpdateOrder = (data,id) =>{
  let res;
  try {
    res = axiosInstance.put(`wc/v3/orders/${id}`,data,Authentication);
  } catch (error) {
    console.log(error);
  }
  return res ? res : "";
}

export const getCustomerOrderList = (customer) =>{
  let res;
  try {
    res = axiosInstance.get(`wc/v3/orders?customer=${customer}`,Authentication);
  } catch (error) {
    console.log(error);
  }
  return res ? res : "";
}


export const getCustomerOrderbyID = (id) =>{
  let res;
  try {
    res = axiosInstance.get(`wc/v3/orders/${id}`,Authentication);
  } catch (error) {
    console.log(error);
  }
  return res ? res : "";
}


export const getCustomerbyID = (id) =>{
  let res;
  try {
    res = axiosInstance.get(`wc/v3/customers/${id}`,Authentication);
  } catch (error) {
    console.log(error);
  }
  return res ? res : "";
}

export const updateCustomerbyID = (id,data) =>{
  let res;
  try {
    res = axiosInstance.put(`wc/v3/customers/${id}`,data,Authentication);
  } catch (error) {
    console.log(error);
  }
  return res ? res : "";
}
