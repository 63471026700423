import React, { useState } from "react"
import { NavLink, Navbar,Container,Row,Col } from "react-bootstrap";  
import menuIcon from '../../assets/images/menu-icon.png';
import Menu from "../header/menu";


const FooterLanding = () => {
    const [menuToggle,SetmenuToggle] = useState(true);

    const footerToggle = () => {
        // console.log(menuToggle);
       if(menuToggle)
       { document.getElementById("FootermyNav").style.height = "33%";
       setTimeout(() => {
        document.getElementById("FootermyNav").style.height = "fit-content";

      }, 400);
       document.getElementById("myNav").style.height = "0%";
        SetmenuToggle(false);}
        else
       { document.getElementById("FootermyNav").style.height = "0%";
        SetmenuToggle(true);}

      }
      const closeMenu = () =>{
        document.getElementById("FootermyNav").style.height = "0%";
        document.getElementById("myNav").style.height = "0%";
        // SetmenuToggle(true);
      }
    return (
        <>
        <Row className="footer"  >

<Col className="headerLeft">
<img className="menuIcon" onMouseEnter={footerToggle} src={menuIcon} /> 
</Col>


<Col className="headerRight"> 
<img className="menuIcon" onMouseEnter={footerToggle} src={menuIcon} /> 
</Col>

<div className="overlay"  id="FootermyNav"  onMouseLeave={footerToggle} > 
<a   className="closebtn"  onClick={closeMenu} >&times;</a>

<div className="overlay-content menuitem">
     
     <Menu />
    
</div>
</div>

</Row>
 
 </>
    );
}

export default FooterLanding;
