import { Elements, PaymentElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { async } from 'q';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getPaymentIntent } from '../../axiosCalls/pages';
import Checkout from '../../pages/checkout';

const PaymentElements = () => {
    const [loading, setLoading] = useState(false);
    // const [clientsecret,setClinetSecret] = useState();

    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
    const cartData = useSelector((state) => state).addtocart.addtocart;

   const fetchData = async() =>{
    // if(!localStorage.getItem("client_secret")){

    // const res = await getPaymentIntent(cartData.totals.total);
    // console.log("test-12")
    // localStorage.setItem("client_secret", res.data.data.client_secret);
    // setClinetSecret(localStorage.getItem("client_secret"))

    // setLoading(false)
    // }
   }
    fetchData();
 
    
    // setClinetSecret(localStorage.getItem("client_secret"))
 
 
 const client_secret = localStorage.getItem("client_secret");
    const options = {
        // passing the client secret obtained from the server
        clientSecret: client_secret,
      };
    return (
        <>
        {loading ? (
            <>
            <span style={{height:"100vh",display:"block",marginTop:"18%",marginLeft:"42%",visibility:"hidden"}}>Loading....</span>
              <div className="loader-cart" style={{position:"absolute",top:"0%",left:"0%"}}>
                <div className="overlay-loader"></div>
                <div className="loader-container" style={{height:"100vh"}}>

                  <div className="spinner"></div>
                </div>
              </div>
            </>
          ) : (
            <>
                 <div key="keyID">
            <Elements stripe={stripePromise}  options={options}>
            <Checkout />
            </Elements>
        </div>
            
            </>
          )}
   

        </>
    );
}

export default PaymentElements;
