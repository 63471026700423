import {
  CardElement,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import {
  addToCartFee,
  clearAllCart,
  createOrder,
  getCartApi,
  getCustomerbyID,
  getPaymentIntent,
  RemoveAddToCartFee,
  updateCustomerbyID,
} from "../axiosCalls/pages";
import { setAddToCart } from "../redux/actions/pagesActions";
import "../styles/css/checkoutStyles.css";
import Select from "react-select";
import { Country, State, City } from "country-state-city";
import { loginApi } from "../axiosCalls/auth";
import { NotificationManager } from "react-notifications";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from "react-toastify";

const Checkout = () => {
  const navigate = useNavigate();
  // country hooks
  const [country_value, setcountry_Value] = useState("");
  const countryCode = "SG";
  const country = Country.getAllCountries();
  // let  orderDetails = '';

  const [orderDetails, setorderDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [gifting, setGifting] = useState(false);
  const [giftingMsg, setGiftingMsg] = useState();

  // console.log(State.getStatesOfCountry('SG'))

  // stripe hooks
  const elements = useElements();
  const stripe = useStripe();
  const dispatch = useDispatch();
  const [nextStep, setNextStep] = useState(false);
  const [onlyOneTimeSetData, setOnlyOneTimeSetData] = useState(true);
  const cartData = useSelector((state) => state).addtocart.addtocart;
  const [checkDeliverBox, setCheckDeliverBox] = useState("0");
  const [step, setStep] = useState(1);
  const [errors, setErrors] = useState([]);
  const [guesterrors, setguesterrors] = useState([]);
  const [countryCodes, setCountryCodes] = useState("");

  const [sameBillingCheck, setSameBillingCheck] = useState(0);

  const [listState, setlistState] = useState([]);
  const [BlistState, setBlistState] = useState([]);
  if (cartData.items.length <= 0 && !orderDetails) {
    window.location.assign("/scarves");
  }

  const [formValue, setFormValue] = useState({
    email: "",
    password: "",
  });

  const [GuestformValue, setGuestFormValue] = useState({
    email: "",
  });
  const [LoginErrors, setLoginErrors] = useState({});
  const token = localStorage.getItem("token");
  if (token && !nextStep) {
    setNextStep(true);
  }
  const handleOnchange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  const validateEmail = (value) => {
    // Regular expression for email validation
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(value);
  };
  const handleChangeGuest = (e) => {
    setGuestFormValue({ ...GuestformValue, [e.target.name]: e.target.value });
  };
  const submitGuestHandler = (e) => {
    const { email } = GuestformValue;
    const emailcheck = validateEmail(email);
    let errors = {};
    if (!email) {
      errors["email"] = "Guest email required";
      setguesterrors(errors);
    } else if (!emailcheck) {
      errors["email"] = "Guest Email is not vaild email";
      setguesterrors(errors);
    } else {
      errors["email"] = "";
      setguesterrors(errors);
      setNextStep(true);
    }
  };
  const handlerChangeGifting = (e) => {
    if (e.target.value === "false") {
      setGifting(true);
    } else {
      setGifting(false);
    }
  };
  const submitHandler = async () => {
    const { email, password } = formValue;
    let errors = {};
    const emailcheck = validateEmail(email);

    if (!email) {
      errors["email"] = "Email is required";
      setLoginErrors(errors);
    } else if (!password) {
      errors["password"] = "Password is required";
      setLoginErrors(errors);
    } else if (!emailcheck) {
      errors["email"] = "Email is not vaild email";
      setLoginErrors(errors);
    } else if (Object.keys(errors).length <= 0) {
      setLoading(true)
      errors["email"] = "";
      errors["password"] = "";
      setLoginErrors(errors);

      await loginApi(email, password).then(async (res) => {
        setLoading(false)
        localStorage.setItem("token", res.data.data.token);
        localStorage.setItem("user_id", res.data.data.id);
        const cusRes = await getCustomerbyID(res.data.data.id);
        localStorage.setItem(
          "billing_details",
          JSON.stringify(cusRes.data.billing)
        );
        localStorage.setItem(
          "shipping_details",
          JSON.stringify(cusRes.data.shipping)
        );
        // NotificationManager.success("Welcome", "", 1000);

        setTimeout(function() {
          window.location.reload();
        }, 1000);
      });
    } else {
      setErrors(errors);
    }
  };

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    address_1: "",
    address_2: "",
    zipcode: "",
    country: "",
    city: "",
    state: "",
    mobile_number: "",
    billing_shipping_same: "",
    biiling_firstName: "",
    biiling_lastName: "",
    biiling_address_1: "",
    biiling_address_2: "",
    biiling_zipcode: "",
    biiling_city: "",
    biiling_state: "",
    biiling_mobile_number: "",
    billing_country: "",
  });

  //  const cities =  City.getCitiesOfState(countryCode, formData.state);

  const handleChangeDelivery = async (e) => {
    setCheckDeliverBox(e.target.value);
    const resRemoveFee = await RemoveAddToCartFee();
    // console.log(e.target.value);
    const res = await addToCartFee(e.target.value);

    const cartGet = await getCartApi(localStorage.getItem("cart_key"));

    localStorage.setItem("cart_items", JSON.stringify(cartGet.data));
  };
  if (step === 1 && onlyOneTimeSetData) {
    const BillingDetails = JSON.parse(localStorage.getItem("billing_details"));
    if (BillingDetails) {
      if (BillingDetails.first_name) {
        formData.biiling_firstName = BillingDetails.first_name;
      }
      if (BillingDetails.last_name) {
        formData.biiling_lastName = BillingDetails.last_name;
      }
      if (BillingDetails.address_1) {
        formData.biiling_address_1 = BillingDetails.address_1;
      }
      if (BillingDetails.address_2) {
        formData.biiling_address_2 = BillingDetails.address_2;
      }
      if (BillingDetails.postcode) {
        formData.biiling_zipcode = BillingDetails.postcode;
      }
      if (BillingDetails.phone) {
        formData.biiling_mobile_number = BillingDetails.phone;
      }
      if (BillingDetails.city) {
        formData.biiling_city = BillingDetails.city;
      }
    }

    const ShippingDetails = JSON.parse(
      localStorage.getItem("shipping_details")
    );
    if (ShippingDetails) {
      if (ShippingDetails.first_name) {
        formData.firstName = ShippingDetails.first_name;
      }
      if (ShippingDetails.last_name) {
        formData.lastName = ShippingDetails.last_name;
      }
      if (ShippingDetails.address_1) {
        formData.address_1 = ShippingDetails.address_1;
      }
      if (ShippingDetails.address_2) {
        formData.address_2 = ShippingDetails.address_2;
      }
      if (ShippingDetails.postcode) {
        formData.zipcode = ShippingDetails.postcode;
      }
      if (ShippingDetails.phone) {
        formData.mobile_number = ShippingDetails.phone;
      }
    }
    setOnlyOneTimeSetData(false);
  }
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({ ...prevData, [name]: value }));

    if (e.target.name === "country") {
      const states = State.getStatesOfCountry(e.target.value);

      if (states.length === 0) {
        const newcountry = Country.getCountryByCode(e.target.value);
        const newState = [
          {
            isoCode: e.target.value,
            name: newcountry.name,
          },
        ];

        setlistState(newState);
      } else {
        setlistState(states);
      }
    }

    if (e.target.name === "billing_country") {
      const states = State.getStatesOfCountry(e.target.value);
      if (states.length === 0) {
        const newcountry = Country.getCountryByCode(e.target.value);
        const newState = [
          {
            isoCode: e.target.value,
            name: newcountry.name,
          },
        ];
        setBlistState(newState);
      } else {
        setBlistState(states);
      }
    }

    if (e.target.name == "billing_shipping_same" && sameBillingCheck == 0) {
      const StateNew = formData.state;
      formData.biiling_firstName = formData.firstName;
      formData.biiling_lastName = formData.lastName;
      formData.biiling_address_1 = formData.address_1;
      formData.biiling_address_2 = formData.address_2;
      formData.biiling_zipcode = formData.zipcode;
      formData.biiling_city = formData.city;
      formData.biiling_state = StateNew;
      formData.biiling_mobile_number = formData.mobile_number;
      formData.billing_country = formData.country;
      const states = State.getStatesOfCountry(formData.country);
      if (states.length === 0) {
        const newcountry = Country.getCountryByCode(formData.country);
        const newState = [
          {
            isoCode: formData.country,
            name: newcountry.name,
          },
        ];
        setBlistState(newState);
      } else {
        const states = State.getStatesOfCountry(formData.country);
        setBlistState(states);
      }
      formData.biiling_state = formData.state;

      setSameBillingCheck(1);
    } else if (e.target.name == "billing_shipping_same") {
      formData.biiling_firstName = "";
      formData.biiling_lastName = "";
      formData.biiling_address_1 = "";
      formData.biiling_address_2 = "";
      formData.biiling_zipcode = "";
      formData.biiling_city = "";
      formData.biiling_state = "";
      formData.biiling_mobile_number = "";

      const BillingDetails = JSON.parse(
        localStorage.getItem("billing_details")
      );

      if (BillingDetails) {
        if (BillingDetails.first_name) {
          formData.biiling_firstName = BillingDetails.first_name;
        }
        if (BillingDetails.last_name) {
          formData.biiling_lastName = BillingDetails.last_name;
        }
        if (BillingDetails.address_1) {
          formData.biiling_address_1 = BillingDetails.address_1;
        }
        if (BillingDetails.address_2) {
          formData.biiling_address_2 = BillingDetails.address_2;
        }
        if (BillingDetails.postcode) {
          formData.biiling_zipcode = BillingDetails.postcode;
        }
        if (BillingDetails.phone) {
          formData.biiling_mobile_number = BillingDetails.phone;
        }
      }

      setSameBillingCheck(0);
    }
  };

  const handleNext = async () => {
    const newErrors = [];
    if (step === 1) {
      // localStorage.removeItem("client_secret");
      if (formData.firstName === "") {
        newErrors["firstName"] = "First Name is required";
      }
      if (formData.lastName === "") {
        newErrors["lastName"] = "Last Name is required";
      }
      if (formData.address_1 === "") {
        newErrors["address_1"] = "Addresss is required";
      }
      if (formData.zipcode === "") {
        newErrors["zipcode"] = "Zip is required";
      }
      if (formData.city === "") {
        newErrors["city"] = "City is required";
      }
      if (formData.state === "") {
        newErrors["state"] = "State is required";
      }
      if (formData.country === "") {
        newErrors["country"] = "Country is required";
      }
      // if (formData.mobile_number === "") {
      //   newErrors["mobile_number"] = "Mobile Number is required";
      // }
      if (formData.biiling_firstName === "") {
        newErrors["biiling_firstName"] = "Billing First Name is required";
      }
      if (formData.biiling_lastName === "") {
        newErrors["biiling_lastName"] = "Billing Last Name is required";
      }
      if (formData.biiling_address_1 === "") {
        newErrors["biiling_address_1"] = "Billing Addresss is required";
      }
      if (formData.biiling_zipcode === "") {
        newErrors["biiling_zipcode"] = "Billing Zip is required";
      }
      if (formData.biiling_city === "") {
        newErrors["biiling_city"] = "Billing City is required";
      }
      if (formData.biiling_state === "") {
        newErrors["biiling_state"] = "Billing State is required";
      }
      if (formData.billing_country === "") {
        newErrors["billing_country"] = "Billing Country is required";
      }
      // if (formData.biiling_mobile_number === "") {
      //   newErrors["biiling_mobile_number"] =
      //     "Billing Mobile Number is required";
      // }
      if (Object.keys(newErrors).length <= 0) {
        setLoading(true);
        setErrors(null);
        // if (!localStorage.getItem("client_secret")) {
        //   const res = await getPaymentIntent(cartData.totals.total);

        //   // update Intent
        //   localStorage.setItem("client_secret", res.data.data.client_secret);

        // }

        var UpdateCustomerData = JSON.stringify({
          shipping: {
            first_name: formData.firstName,
            last_name: formData.lastName,
            address_1: formData.address_1,
            address_2: formData.address_2,

            postcode: formData.zipcode,

            phone: formData.mobile_number,
          },
          billing: {
            first_name: formData.biiling_firstName,
            last_name: formData.biiling_lastName,
            address_1: formData.biiling_address_1,
            address_2: formData.biiling_address_2,

            postcode: formData.biiling_zipcode,

            phone: formData.biiling_mobile_number,
          },
        });

        const userID = localStorage.getItem("user_id");

        if (userID) {
          const cusRes = await updateCustomerbyID(userID, UpdateCustomerData);
          localStorage.setItem(
            "billing_details",
            JSON.stringify(cusRes.data.billing)
          );
          localStorage.setItem(
            "shipping_details",
            JSON.stringify(cusRes.data.shipping)
          );
        }
        setLoading(false);
        setStep((prevStep) => prevStep + 1);
      }

      setErrors(newErrors);
    }
    if (step === 2) {
      setLoading(true);
      const carddetails = elements.getElement(CardElement);

      const client_secret = localStorage.getItem("client_secret");

      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/checkout`,
        },
        payment_method_data: {
          billing_details: {
            name: "Arslan Arshad",
            email: "arslan.arshad@ropstam.com",
          },
        },
        redirect: "if_required",
      });

      if (result.error) {
        // Show error to your customer (for example, payment details incomplete)
        // console.log(result.error.message);
        setLoading(false);
        toast.info(result.error.message);
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
        // console.log(result);
        if (result.paymentIntent.status === "succeeded") {
          const transection_id = result.paymentIntent.id;
          localStorage.removeItem("client_secret");
          localStorage.removeItem("client_secret_ID");
          localStorage.setItem("payment_intent", false);
          orderCreated(transection_id);
        }
      }

      // stripe
      //   .confirmCardPayment(client_secret, {
      //     payment_method: {
      //       card: elements.getElement(CardElement),
      //     },
      //   })
      //   .then(function(result) {

      //     if (result.paymentIntent.status === "succeeded") {
      //       const transection_id = result.paymentIntent.id;
      //       const payment_method = result.paymentIntent.payment_method_types[0];

      //       orderCreated(transection_id, payment_method);
      //     } else {
      //       console.log("ERROR");
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
      // setCardInfo(carddetails);

      // console.log(carddetails);
    }
    // if (step != 2) setStep((prevStep) => prevStep + 1);
  };

  const handlePrevious = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  // setLineItems(mappedData);
  // console.log(mappedData)
  const orderCreated = async (transection_id) => {
    // const mappedData = cartData.items.map((item) => {
    //   const { variation_id, product_id, quantity, meta, cart_item_data } = item;
    //   const { isGifted } = cart_item_data.giftedArray[0];

    //   return {
    //     product_id,
    //     variation_id: item.id,
    //     quantity: quantity.value,

    //     meta_data: [
    //       {
    //         key: "isGifted",
    //         value: isGifted ? cart_item_data.giftedArray : "false",
    //       },
    //       {
    //         key: "Scarf_Statement",
    //         value: cart_item_data.text_on_image,
    //       },
    //     ],
    //   };
    // });

    const mappedData = cartData.items.map((item) => {
      const { id, quantity, product_id, meta, cart_item_data } = item;
      const { product_type } = meta;

      if (product_type === "variation") {
        return {
          product_id: product_id,
          variation_id: id,
          quantity: quantity.value,
          meta_data: [
            {
              key: "Scarf_Statement",
              value: cart_item_data.text_on_image,
            },
          ],
        };
      } else {
        return {
          product_id: id,
          quantity: quantity.value,
        };
      }
    });

    // const mappedDataVariations = cartData.items.map((item) => {
    //     const { id, quantity,product_id } = item;
    //     // const { isGifted } = cart_item_data.giftedArray[0];

    //   });

    let userID = localStorage.getItem("user_id");
    if (!userID) {
      userID = 0;
    }

    let delivryName = cartData.fees["shipping-and-handling"].name;
    let delivryNameFee = cartData.fees["shipping-and-handling"].fee;
    let email = GuestformValue.email;

    if (!email) {
      email = cartData.items[0].cart_item_data.cart_user_email;
    }

    if (delivryName === "" || !delivryName) {
      delivryName = "Free Shipping";
    }
    if (delivryNameFee === "" || !delivryNameFee) {
      delivryNameFee = "0";
    }
    const orderData = JSON.stringify({
      payment_method: "credit-card",
      payment_method_title: "Credit Card Payment",
      customer_id: userID,
      set_paid: true,
      transaction_id: transection_id,
      billing: {
        first_name: formData.firstName,
        last_name: formData.lastName,
        address_1: formData.address_1,
        address_2: formData.address_2,
        city: formData.city,
        state: formData.state,
        postcode: formData.zipcode,
        country: formData.country,
        email: email,
        phone: formData.mobile_number,
      },
      shipping: {
        first_name: formData.biiling_firstName,
        last_name: formData.biiling_lastName,
        address_1: formData.biiling_address_1,
        address_2: formData.biiling_address_2,
        city: formData.biiling_city,
        state: formData.biiling_state,
        postcode: formData.biiling_zipcode,
        country: formData.country,
        email: email,
        phone: formData.biiling_mobile_number,
      },
      line_items: mappedData,
      shipping_lines: [
        {
          method_id: "shipping-and-handling",
          method_title: delivryName,
          total: delivryNameFee,
        },
      ],
      meta_data: [
        {
          key: "transection_id",
          value: transection_id,
        },
        {
          key: "isGifting",
          value: gifting,
        },
        {
          key: "Gifting Message",
          value: giftingMsg,
        },
      ],
    });

    const res = await createOrder(orderData);
    setorderDetails(res.data);
    localStorage.removeItem("client_secret");
    localStorage.removeItem("client_secret_ID");
    localStorage.setItem("payment_intent", false);
    const clearCartRes = clearAllCart().then(function(resCart) {
      localStorage.setItem("cart_items", JSON.stringify(resCart.data));

      dispatch(setAddToCart(resCart.data));
    });

    setStep((prevStep) => prevStep + 1);
    setLoading(false);
  };
  return (
    <>
      {loading ? (
        <>
          <div className="overlay-loader"></div>
          <div className="checkout-loader-cantain">
            <div className="spinner"></div>
          </div>
        </>
      ) : (
        ""
      )}

      <div className=" slide-in">
        <main className="checkout-page">
          <div className="row height-page">
            <div className="col-md-8 left-side">
              <div className="parent-side-heading-checkout">
                <h4 className="mb-3 main-heading">Checkout</h4>
              </div>
              {/* <!-- MultiStep Form --> */}
              <div className="row">
                <div className="">
                  <form id="msform" onSubmit={handleSubmit} autoComplete="on">
                    {/* <!-- progressbar --> */}
                    <ul id="progressbar">
                      <li
                        className={
                          step === 1 || step === 2 || step === 3 ? "active" : ""
                        }
                      >
                        <span className="progressbar-items">Cart</span>
                      </li>
                      <li
                        className={
                          step === 1 || step === 2 || step === 3 ? "active" : ""
                        }
                      >
                        <span className="progressbar-items">Payment</span>
                      </li>
                      <li className={step === 2 || step === 3 ? "active" : ""}>
                        <span className="progressbar-items">Confirmation</span>
                      </li>
                      <li className={step === 3 || step === 3 ? "active" : ""}>
                        <span className="progressbar-items"></span>
                      </li>
                    </ul>

                    {/* <!-- fieldsets --> */}
                    {step === 1 &&
                      (!nextStep ? (
                        <>
                          <div className="shipping-top-heading">
                            Returning Customer
                            <div className="checkout-guest">
                              {" "}
                              Checkout as Guest
                            </div>
                          </div>
                          <fieldset>
                            {/*  */}

                            <div className="row ">
                              <div className="col feild50 login-fileds-checkout">
                                <input
                                  onChange={handleOnchange}
                                  type="email"
                                  className="form-control "
                                  id="email"
                                  name="email"
                                  placeholder="* Enter Email Address"
                                  required
                                />

                                <div className="invalid-feedback">
                                  {LoginErrors && LoginErrors.email}
                                </div>
                                <input
                                  onChange={handleOnchange}
                                  type="password"
                                  className="form-control "
                                  id="password"
                                  name="password"
                                  placeholder="* Enter Password"
                                  required
                                />

                                <div className="invalid-feedback">
                                  {LoginErrors && LoginErrors.password}
                                </div>
                                <button
                                  className="checkout-btns"
                                  type="button"
                                  onClick={submitHandler}
                                >
                                  Sign In
                                </button>
                              </div>
                              <div className="col">
                                <input
                                  onChange={handleChangeGuest}
                                  type="email"
                                  className="form-control "
                                  id="Email"
                                  name="email"
                                  placeholder="*Enter Email Address"
                                  required
                                />
                                <div className="invalid-feedback">
                                  {guesterrors && guesterrors.email}
                                </div>
                                <button
                                  className="checkout-btns"
                                  type="button"
                                  onClick={submitGuestHandler}
                                >
                                  Checkout as Guest
                                </button>
                              </div>
                            </div>
                          </fieldset>
                        </>
                      ) : (
                        <>
                          <div className="shipping-top-heading">
                            Shipping Address
                            <NavLink to="/scarves" className="contiune-shoping">
                              <div> {"<"} Continue shopping</div>
                            </NavLink>
                          </div>
                          <fieldset>
                            {/*  */}
                            <div className="row">
                              <div className="col feild50">
                                <input
                                  onChange={handleChange}
                                  type="text"
                                  className="form-control "
                                  id="firstName"
                                  name="firstName"
                                  value={formData.firstName}
                                  placeholder="* First name"
                                  required
                                />

                                <div className="invalid-feedback">
                                  {errors && errors.firstName}
                                </div>
                              </div>
                              <div className="col">
                                <input
                                  onChange={handleChange}
                                  type="text"
                                  className="form-control "
                                  id="lastName"
                                  name="lastName"
                                  value={formData.lastName}
                                  placeholder="* Last name"
                                  required
                                />
                                <div className="invalid-feedback">
                                  {errors && errors.lastName}
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col feild50">
                                <input
                                  onChange={handleChange}
                                  type="text"
                                  className="form-control "
                                  id="address_1"
                                  value={formData.address_1}
                                  name="address_1"
                                  placeholder="* Address 1"
                                  required
                                />
                                <div className="invalid-feedback">
                                  {errors && errors.address_1}
                                </div>
                              </div>
                              <div className="col">
                                <input
                                  onChange={handleChange}
                                  type="text"
                                  className="form-control "
                                  id="address_2"
                                  value={formData.address_2}
                                  name="address_2"
                                  placeholder="Address 2"
                                  required
                                />
                                {/* <div className="invalid-feedback">
                                Valid first name is required.
                              </div> */}
                              </div>
                            </div>

                            <div className="row">
                              <div className="col feild50">
                                <input
                                  onChange={handleChange}
                                  type="text"
                                  className="form-control "
                                  value={formData.zipcode}
                                  id="zipcode"
                                  name="zipcode"
                                  placeholder="* Zipcode/Postal code"
                                  required
                                />
                                <div className="invalid-feedback">
                                  {errors && errors.zipcode}
                                </div>
                              </div>
                              <div className="col feild50">
                                <select
                                  onChange={handleChange}
                                  type="text"
                                  className="form-control "
                                  id="country"
                                  name="country"
                                  placeholder="* Country"
                                  required
                                >
                                  <option value="">Choose Country</option>
                                  {country.map((crnty, index) => {
                                    return (
                                      <option key={index} value={crnty.isoCode}>
                                        {crnty.name}
                                      </option>
                                    );
                                  })}
                                </select>
                                <div className="invalid-feedback">
                                  {errors && errors.country}
                                </div>
                              </div>
                              <div className="col ">
                                <select
                                  onChange={handleChange}
                                  type="text"
                                  className="form-control "
                                  id="state"
                                  name="state"
                                  placeholder="* State"
                                  required
                                >
                                  <option value="">Choose State</option>
                                  {listState.map((state, index) => {
                                    return (
                                      <option key={index} value={state.isoCode}>
                                        {state.name}
                                      </option>
                                    );
                                  })}
                                </select>
                                <div className="invalid-feedback">
                                  {errors && errors.state}
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col feild50">
                                <input
                                  onChange={handleChange}
                                  type="text"
                                  className="form-control"
                                  id="city"
                                  name="city"
                                  placeholder="* City"
                                  required
                                />
                                {/* <option value="">Choose city</option>
                                  {cities.map((city, index) => {
                                    return <option value={city}>{city}</option>;
                                  })} */}

                                <div className="invalid-feedback">
                                  {errors && errors.city}
                                </div>
                              </div>

                              <div className="col ">
                                <input
                                  onChange={handleChange}
                                  type="number"
                                  className="form-control "
                                  id="mobile_number"
                                  value={formData.mobile_number}
                                  name="mobile_number"
                                  placeholder="Mobile Number"
                                />{" "}
                                <div className="invalid-feedback">
                                  {errors && errors.mobile_number}
                                </div>
                                <div
                                  style={{
                                    fontSize: "9px",
                                    textAlign: "left",
                                    color: "rgb(167 167 167)",
                                  }}
                                >
                                  Mobile phone number will only be used for
                                  important questions related to your order.
                                </div>
                              </div>
                            </div>
                            {/*  */}

                            <div className="return-information">
                              {/* <button className="add-to-cart-btn">Buy Now</button> */}
                            </div>

                            {/* end of left side */}
                            {/* <div
                              className="shipping-top-heading"
                              style={{
                                textAlign: "left",
                                paddingLeft: "0px",
                                marginTop: "-17px",
                              }}
                            >
                              Delivery Options
                            </div>

                                                            checked={cartData.fees["delivery-charges"] ? cartData.fees["delivery-charges"].fee === 0 ? true : false : true }
                             

                            <div
                              className="Billing-address-box"
                              style={{ marginBottom: "30px" }}
                            >
                              <div className="checkbox delivery">
                                <div className="checkbox-wrapper-37">
                                  <input
                                    onChange={handleChangeDelivery}
                                    type="radio"
                                    value={0}
                                    checked={
                                      checkDeliverBox === "0" && "checked"
                                    }
                                    name="billing_shipping_same"
                                    id={"checkbox-wrapper-1"}
                                  />
                                  <label
                                    htmlFor={"checkbox-wrapper-1"}
                                    className="terms-label"
                                  >
                                    <svg
                                      className="checkbox-svg"
                                      viewBox="0 0 200 200"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <mask
                                        id="path-1-inside-1_476_5-37"
                                        fill="white"
                                      >
                                        <rect width="200" height="200" />
                                      </mask>
                                      <rect
                                        width="200"
                                        height="200"
                                        className="checkbox-box"
                                        strokeWidth="40"
                                        mask="url(#path-1-inside-1_476_5-37)"
                                      />
                                      <path
                                        className="checkbox-tick"
                                        d="M52 111.018L76.9867 136L149 64"
                                        strokeWidth="15"
                                      />
                                    </svg>

                                    <span className="label-text">
                                      Free {"   "} Standard
                                    </span>

                                    <span className="label-text">
                                      2-3 business days
                                    </span>
                                  </label>

                                  <input
                                    onChange={handleChangeDelivery}
                                    type="radio"
                                    value={15}
                                    checked={
                                      checkDeliverBox === "15" && "checked"
                                    }
                                    name="billing_shipping_same"
                                    id={"checkbox-wrapper-2"}
                                  />
                                  <label
                                    htmlFor={"checkbox-wrapper-2"}
                                    className="terms-label"
                                  >
                                    <svg
                                      className="checkbox-svg"
                                      viewBox="0 0 200 200"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <mask
                                        id="path-1-inside-1_476_5-37"
                                        fill="white"
                                      >
                                        <rect width="200" height="200" />
                                      </mask>
                                      <rect
                                        width="200"
                                        height="200"
                                        className="checkbox-box"
                                        strokeWidth="40"
                                        mask="url(#path-1-inside-1_476_5-37)"
                                      />
                                      <path
                                        className="checkbox-tick"
                                        d="M52 111.018L76.9867 136L149 64"
                                        strokeWidth="15"
                                      />
                                    </svg>

                                    <span className="label-text">
                                      $15 {"   "} Second Day
                                    </span>

                                    <span className="label-text">
                                      2 business days
                                    </span>
                                  </label>

                                  <input
                                    onChange={handleChangeDelivery}
                                    type="radio"
                                    value={25}
                                    checked={
                                      checkDeliverBox === "25" && "checked"
                                    }
                                    name="billing_shipping_same"
                                    id={"checkbox-wrapper-3"}
                                  />
                                  <label
                                    htmlFor={"checkbox-wrapper-3"}
                                    className="terms-label"
                                  >
                                    <svg
                                      className="checkbox-svg"
                                      viewBox="0 0 200 200"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <mask
                                        id="path-1-inside-1_476_5-37"
                                        fill="white"
                                      >
                                        <rect width="200" height="200" />
                                      </mask>
                                      <rect
                                        width="200"
                                        height="200"
                                        className="checkbox-box"
                                        strokeWidth="40"
                                        mask="url(#path-1-inside-1_476_5-37)"
                                      />
                                      <path
                                        className="checkbox-tick"
                                        d="M52 111.018L76.9867 136L149 64"
                                        strokeWidth="15"
                                      />
                                    </svg>

                                    <span className="label-text">
                                      $25 {"   "} Overnight
                                    </span>

                                    <span className="label-text">
                                      1 business days
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div> */}

                            <div
                              className="shipping-sub-top-heading"
                              style={{
                                textAlign: "left",
                                paddingLeft: "0px",
                                marginTop: "-17px",
                              }}
                            >
                              Billing Address
                            </div>

                            <div className="Billing-address-box">
                              <div className="checkbox">
                                <div className="checkbox-wrapper-37">
                                  <input
                                    onChange={handleChange}
                                    type="checkbox"
                                    value={sameBillingCheck}
                                    name="billing_shipping_same"
                                    id={"checkbox-wrapper-37"}
                                  />
                                  <label
                                    htmlFor={"checkbox-wrapper-37"}
                                    className="terms-label"
                                  >
                                    <svg
                                      className="checkbox-svg"
                                      viewBox="0 0 200 200"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <mask
                                        id="path-1-inside-1_476_5-37"
                                        fill="white"
                                      >
                                        <rect width="200" height="200" />
                                      </mask>
                                      <rect
                                        width="200"
                                        height="200"
                                        className="checkbox-box"
                                        strokeWidth="40"
                                        mask="url(#path-1-inside-1_476_5-37)"
                                      />
                                      <path
                                        className="checkbox-tick"
                                        d="M52 111.018L76.9867 136L149 64"
                                        strokeWidth="15"
                                      />
                                    </svg>
                                    <span className="label-text">
                                      My billing address and shipping address
                                      are the same
                                    </span>
                                  </label>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col feild50">
                                  <input
                                    onChange={handleChange}
                                    type="text"
                                    className="form-control"
                                    id="biiling_firstName"
                                    readOnly={
                                      sameBillingCheck === 1 && "readonly"
                                    }
                                    name="biiling_firstName"
                                    placeholder="* First name"
                                    value={formData.biiling_firstName}
                                    required
                                  />
                                  <div className="invalid-feedback">
                                    {errors && errors.biiling_firstName}
                                  </div>
                                </div>
                                <div className="col">
                                  <input
                                    onChange={handleChange}
                                    type="text"
                                    className="form-control "
                                    id="biiling_lastName"
                                    name="biiling_lastName"
                                    readOnly={
                                      sameBillingCheck === 1 && "readonly"
                                    }
                                    placeholder="* Last name"
                                    value={formData.biiling_lastName}
                                    required
                                  />
                                  <div className="invalid-feedback">
                                    {errors && errors.biiling_lastName}
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col feild50">
                                  <input
                                    onChange={handleChange}
                                    type="text"
                                    className="form-control "
                                    id="biiling_address_1"
                                    readOnly={
                                      sameBillingCheck === 1 && "readonly"
                                    }
                                    name="biiling_address_1"
                                    value={formData.biiling_address_1}
                                    placeholder="* Address 1"
                                    required
                                  />
                                  <div className="invalid-feedback">
                                    {errors && errors.biiling_address_1}
                                  </div>
                                </div>
                                <div className="col">
                                  <input
                                    onChange={handleChange}
                                    type="text"
                                    className="form-control "
                                    value={formData.biiling_address_2}
                                    id="biiling_address_2"
                                    name="biiling_address_2"
                                    readOnly={
                                      sameBillingCheck === 1 && "readonly"
                                    }
                                    placeholder="Address 2"
                                    required
                                  />
                                </div>
                              </div>

                              <div className="row">
                                <div className="col feild50">
                                  <input
                                    onChange={handleChange}
                                    type="text"
                                    className="form-control "
                                    readOnly={
                                      sameBillingCheck === 1 && "readonly"
                                    }
                                    id="biiling_zipcode"
                                    value={formData.biiling_zipcode}
                                    name="biiling_zipcode"
                                    placeholder="* Zipcode/Postal code"
                                    required
                                  />
                                  <div className="invalid-feedback">
                                    {errors && errors.biiling_zipcode}
                                  </div>
                                </div>
                                <div className="col feild50">
                                  <select
                                    onChange={handleChange}
                                    type="text"
                                    className="form-control "
                                    id="billing_country"
                                    disabled={
                                      sameBillingCheck === 1 && "disabled"
                                    }
                                    name="billing_country"
                                    placeholder="* Country"
                                    required
                                  >
                                    <option value="">Choose Country</option>
                                    {country.map((crnty, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={crnty.isoCode}
                                          selected={
                                            sameBillingCheck === 1 &&
                                            crnty.isoCode === formData.country
                                              ? true
                                              : false
                                          }
                                        >
                                          {crnty.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  <div className="invalid-feedback">
                                    {errors && errors.billing_country}
                                  </div>
                                </div>
                                <div className="col ">
                                  <select
                                    onChange={handleChange}
                                    type="text"
                                    className="form-control "
                                    id="biiling_state"
                                    name="biiling_state"
                                    disabled={
                                      sameBillingCheck === 1 && "disabled"
                                    }
                                    placeholder="* State"
                                    required
                                  >
                                    <option value="">Choose State</option>
                                    {BlistState.map((state, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={state.isoCode}
                                          selected={
                                            sameBillingCheck === 1 &&
                                            state.isoCode === formData.state
                                              ? true
                                              : false
                                          }
                                        >
                                          {state.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  <div className="invalid-feedback">
                                    {errors && errors.biiling_state}
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col feild50">
                                  <input
                                    onChange={handleChange}
                                    type="text"
                                    className="form-control "
                                    id="biiling_city"
                                    readOnly={
                                      sameBillingCheck === 1 && "readonly"
                                    }
                                    value={formData.biiling_city}
                                    name="biiling_city"
                                    placeholder="* City"
                                    required
                                  />
                                  <div className="invalid-feedback">
                                    {errors && errors.biiling_city}
                                  </div>
                                </div>
                                <div className="col">
                                  <input
                                    onChange={handleChange}
                                    type="number"
                                    className="form-control "
                                    value={formData.biiling_mobile_number}
                                    id="biiling_mobile_number"
                                    name="biiling_mobile_number"
                                    readOnly={
                                      sameBillingCheck === 1 && "readonly"
                                    }
                                    placeholder="Mobile Number"
                                  />
                                  <div className="invalid-feedback">
                                    {errors && errors.biiling_mobile_number}
                                  </div>
                                  <div
                                    style={{
                                      fontSize: "9px",
                                      textAlign: "left",
                                      color: "rgb(167 167 167)",
                                    }}
                                  >
                                    Mobile phone number will only be used for
                                    important questions related to your order.
                                  </div>
                                </div>
                              </div>
                              {/*  */}

                              <div className="return-information">
                                {/* <button className="add-to-cart-btn">Buy Now</button> */}
                              </div>

                              {/* end of left side */}
                            </div>

                            <div
                              className="shipping-sub-top-heading"
                              style={{
                                textAlign: "left",
                                paddingLeft: "10px",
                                marginTop: "23px",
                                marginBottom: "-21px",
                              }}
                            >
                              Gift Options
                            </div>
                            <div
                              className="Billing-address-box"
                              style={{ marginBottom: "30px" }}
                            >
                              <div className="checkbox-wrapper-37">
                                <input
                                  onChange={handlerChangeGifting}
                                  type="checkbox"
                                  value={gifting}
                                  name="giftingCheckbox"
                                  id={"checkbox-wrapper-gifting"}
                                />
                                <label
                                  htmlFor={"checkbox-wrapper-gifting"}
                                  className="terms-label"
                                >
                                  <svg
                                    className="checkbox-svg"
                                    viewBox="0 0 200 200"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <mask
                                      id="path-1-inside-1_476_5-37"
                                      fill="white"
                                    >
                                      <rect width="200" height="200" />
                                    </mask>
                                    <rect
                                      width="200"
                                      height="200"
                                      className="checkbox-box"
                                      strokeWidth="40"
                                      mask="url(#path-1-inside-1_476_5-37)"
                                    />
                                    <path
                                      className="checkbox-tick"
                                      d="M52 111.018L76.9867 136L149 64"
                                      strokeWidth="15"
                                    />
                                  </svg>

                                  <span className="label-text">Gifting</span>
                                </label>

                                <textarea
                                  placeholder="Message"
                                  onChange={(e) =>
                                    setGiftingMsg(e.target.value)
                                  }
                                  style={{ textAlign: "left" }}
                                ></textarea>
                              </div>
                            </div>
                          </fieldset>
                        </>
                      ))}
                    {step === 2 && (
                      <fieldset>
                        <PaymentElement />

                        {/* <CardElement
                          name="card-element"
                          options={CARD_ELEMENT_OPTIONS}
                        /> */}
                      </fieldset>
                    )}
                    {step === 3 && (
                      <fieldset className="fieldset3">
                        <>
                          <div
                            className="Billing-address-box"
                            style={{ marginBottom: "30px" }}
                          >
                            <div className="thank-you-page">
                              <strong>{orderDetails.billing.first_name}</strong>{" "}
                              {orderDetails.billing.last_name} <br />
                              Thank you for your order <br />
                              we can't wait to see you being les coy! <br />
                              {"#"}belesscoy {"#"}bibi <br />
                              <br />
                              <br />
                              Your Order #{orderDetails.id} is being processed.
                              You will receive a confirmation email soon.
                            </div>
                          </div>

                          <div
                            className="Billing-address-box"
                            style={{ marginBottom: "30px" }}
                          >
                            <div className="thank-you-page table-responsive">
                              <table style={{ width: "100%" }}>
                                <thead>
                                  <th>Delivery Address</th>
                                  <th>Shipping and handling</th>
                                  <th>Payment</th>
                                  <th>Email</th>
                                  <th>Gift</th>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      {orderDetails.billing.address_1}
                                      <br />
                                      {orderDetails.billing.address_2}
                                      <br />
                                      {orderDetails.billing.city}
                                      <br />
                                    </td>

                                    <td>
                                      {orderDetails.currency_symbol}{" "}
                                      {orderDetails.shipping_lines[0].total}
                                    </td>
                                    <td>{orderDetails.payment_method_title}</td>
                                    <td>{orderDetails.billing.email}</td>
                                    <td>
                                      {orderDetails.meta_data[1].value
                                        ? "Yes"
                                        : "No"}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <NavLink
                            to="/scarves"
                            style={{
                              textDecoration: "underline",
                              color: "#E1523E",
                            }}
                          >
                            Continue Shoping
                          </NavLink>
                        </>
                      </fieldset>
                    )}

                    {/* {step != 1 && (
                      <input
                        onClick={handlePrevious}
                        type="button"
                        name="previous"
                        className="previous action-button-previous"
                        value="Previous"
                        style={}
                      />
                    )} */}
                    {step != 3 &&
                      (nextStep ? (
                        <button
                          onClick={handleNext}
                          type="button"
                          name="next"
                          className="checkout-btns"
                        >
                          {step === 2 ? "Order and Pay" : "Next"}
                        </button>
                      ) : (
                        ""
                      ))}
                    {step === 3 && (
                      <></>
                      // <input
                      //   type="submit"
                      //   name="submit"
                      //   className="submit action-button"
                      //   value="Submit"
                      // />
                    )}
                  </form>
                </div>
              </div>
              {/* <!-- /.MultiStep Form --> */}
            </div>

            <div className="col-md-4 top-border right-side">
              <div className="items-list">
                <div className="shopping-bag-top-head">
                  <div>
                    <strong>
                      {!orderDetails ? "Shopping Bag" : "Order Items"}
                    </strong>
                  </div>
                  <div>
                    {cartData.item_count > 0
                      ? cartData.item_count
                      : orderDetails.line_items.length}{" "}
                    Items
                  </div>
                  {/* <div>Edit</div> */}
                </div>

                {!orderDetails
                  ? cartData.items.map((item, index) => {
                      return (
                        <>
                          {" "}
                          {item.item_key && (
                            <div className="item" key={index}>
                              <div className="image">
                                <img src={item.featured_image} alt="" />
                                <div className="description">
                                  <span>{/* <strong>Bibi</strong> */}</span>
                                  <span className="product-name">
                                    <strong> {item.name}</strong>
                                    <span className="checkout- qty">
                                      {item.quantity.value} QTY
                                    </span>
                                  </span>

                                  <span className="product-name">
                                    160x80 cm{" "}
                                    <span className="">
                                      {cartData.currency.currency_symbol}{" "}
                                      {item.totals.total}
                                    </span>
                                  </span>

                                  {item.cart_item_data.giftedArray ? (
                                    item.cart_item_data.giftedArray[0]
                                      .isGifted ? (
                                      <>
                                        <span>
                                          <strong>Gifts</strong>
                                        </span>

                                        {item.cart_item_data.giftedArray.map(
                                          (gift) => {
                                            return (
                                              <>
                                                <div className="extra-address-items">
                                                  {" "}
                                                  <span className="">
                                                    {gift.address}
                                                  </span>
                                                  <span className="extra-address-items-qty">
                                                    {gift.qty} qty
                                                  </span>
                                                </div>
                                              </>
                                            );
                                          }
                                        )}
                                      </>
                                    ) : (
                                      ""
                                    )
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      );
                    })
                  : orderDetails &&
                    orderDetails.line_items.map((item, index) => {
                      return (
                        <>
                          {" "}
                          {item.id && (
                            <div className="item" key={index}>
                              <div className="image">
                                <img src={item.image.src} alt="" />
                                <div className="description">
                                  <span>{/* <strong>Bibi</strong> */}</span>
                                  <span className="product-name">
                                    <strong> {item.name}</strong>
                                    <span className="checkout- qty">
                                      {item.quantity} QTY
                                    </span>
                                  </span>

                                  <span className="product-name">
                                    160x80 cm{" "}
                                    <span className="">
                                      {orderDetails.currency_symbol}{" "}
                                      {item.total}
                                    </span>
                                  </span>

                                  {/* {item.cart_item_data.giftedArray ? (
                                  item.cart_item_data.giftedArray[0]
                                    .isGifted ? (
                                    <>
                                      <span>
                                        <strong>Gifts</strong>
                                      </span>

                                      {item.cart_item_data.giftedArray.map(
                                        (gift) => {
                                          return (
                                            <>
                                              <div className="extra-address-items">
                                                {" "}
                                                <span className="">
                                                  {gift.address}
                                                </span>
                                                <span className="extra-address-items-qty">
                                                  {gift.qty} qty
                                                </span>
                                              </div>
                                            </>
                                          );
                                        }
                                      )}
                                    </>
                                  ) : (
                                    ""
                                  )
                                ) : (
                                  ""
                                )} */}
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      );
                    })}
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Checkout;
