import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import logo from "../assets/images/logo.png";
import { NavLink, useNavigate } from "react-router-dom";
import { loginApi } from "../axiosCalls/auth";
import { isLogin } from "../components/includes/utils";
import { NotificationManager } from "react-notifications";
import NotificationContainer from "react-notifications/lib/NotificationContainer";
import { async } from "q";
import { getCustomerbyID } from "../axiosCalls/pages";

const Login = () => {
  const navigate = useNavigate();

  const [formValue, setFormValue] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const handleOnchange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  const validateEmail = (value) => {
    // Regular expression for email validation
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(value);
  };
  const submitHandler = async () => {
    const { email, password } = formValue;
    let errors = {};
    const emailcheck = validateEmail(email);
     if (!email) {
      errors["email"] = "Email is required";
      setErrors(errors);
    }else if(!emailcheck){
      errors["email"] = "Email is not vaild email";
      setErrors(errors);
    } else if (!password) {
      errors["password"] = "Password is required";
      setErrors(errors);
    } else if (Object.keys(errors).length <= 0) { 
      setLoading(true)
      errors["email"] = "";
      errors["password"] = "";
      setErrors(errors);

      await loginApi(email, password).then(async(res) => {
       
         localStorage.setItem("token", res.data.data.token);
        localStorage.setItem("user_id", res.data.data.id);
        localStorage.setItem("userEmail",email)
        const cusRes = await getCustomerbyID(res.data.data.id);
        localStorage.setItem(
          "billing_details",
          JSON.stringify(cusRes.data.billing)
        );
        localStorage.setItem(
          "shipping_details",
          JSON.stringify(cusRes.data.shipping)
        );
        setLoading(false)
        NotificationManager.success(
          "Welcome!","",1000
        );

        setTimeout(function() {
          navigate("/my-account");
        }, 2000);

       }).catch((error)=>{
        setLoading(false)
       });
    } else {
      setErrors(errors);
    }
  };
  
 
  onkeydown = (e) => {
    if (e.keyCode === 13) {
    submitHandler();
    }
  };
  return (
    <>
     {loading &&
    <>
    <div className="myaccount-loader"> 
    <div className="overlay-loader" key="005"> </div>
    <div className="spinner" key="006"></div>
    </div>
  </>
    }
      <div className="center">
      <NavLink to='/home'>
        <img className="logo" src={logo} />
      </NavLink>
      </div>

      <div className="center-form slide-in">
        <p className="sub-heading">Welcome</p>
        <h1 className="main-heading">Login to your account</h1>

        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              name="email"
              onChange={handleOnchange}
              placeholder="Enter email"
            />
            <Form.Text className="text-muted">
              {errors.email && <p className="errorText">{errors.email}</p>}
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              name="password"
              onChange={handleOnchange}
              placeholder="Password"
            />
            <Form.Text className="text-muted">
              {errors.password && (
                <p className="errorText">{errors.password}</p>
              )}
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3 form-group" controlId="formBasicCheckbox">
            <Form.Check type="checkbox" label="Remember me" />
          </Form.Group>

          <Form.Label style={{ float: "right", marginTop: "-35px" }}>
            <NavLink to="/forgot-password">Forgot password?</NavLink>
          </Form.Label>

          <Button
            className="submit-button"
            onClick={submitHandler}
            type="button"
          >
            Login now
          </Button>
          <NavLink
            className="login-here center"
            style={{ marginTop: "20px" }}
            to="/sign-up"
          >
            {" "}
            To Sign Up
          </NavLink>
        </Form>
      </div>
      <NotificationContainer />
    </>
  );
};

export default Login;
