import { axiosInstance } from "./axiosInstance";

var Authentication = {
  auth: {
    username: process.env.REACT_APP_USERNAME_KEY,
    password: process.env.REACT_APP_PASSWORD_KEY,
  },
};

export const signUpApi = async (email, password, firstName, lastName) => {
  let res;

  let username = email;
  try {
    var data = JSON.stringify({
      username: username,
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: password,
      display_name:firstName+' '+lastName
    });

    res = axiosInstance.post("/wc/v3/customers", data, Authentication);
  } catch (error) {
    console.log("error");
  }

  return res ? res : "";
};

export const loginApi = async (email, password) => {
  let res;

  try {
    var data = JSON.stringify({
      username: email,
      password: password,
    });
    res = axiosInstance.post("/jwt-auth/v1/token/?path=token/validate", data, Authentication);
  } catch (error) {
    console.log(error);
  }
  return res ? res : "";
};
export const ForgotPasswordApi = async (email) => {
  let res;

  try {
    var data = JSON.stringify({
      email: email,
    });
    res = axiosInstance.post(
      "/bdpwr/v1/reset-password/?path=token/validate",
      data,
      Authentication
    );
  } catch (error) {}
  return res ? res : "";
};

export const ValidateResetPasswordApi = async (code, email) => {
  let res;

  try {
    var data = JSON.stringify({
      email: email,
      code: code,
    });
    res = axiosInstance.post(
      "bdpwr/v1/validate-code/?path=token/validate",
      data,
      Authentication
    );
  } catch (error) {}
  return res ? res : "";
};
export const UpdatePasswordApi = async (email, code, password) => {
  let res;

  try {
    var data = JSON.stringify({
      email: email,
      code: code,
      password: password,
    });
    res = axiosInstance.post(
      "bdpwr/v1/set-password/?path=token/validate",
      data,
      Authentication
    );
  } catch (error) {}
  return res ? res : "";
};
