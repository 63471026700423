import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import SidebarMenu from "../components/header/sidebar-menu";
import { isLogout } from "../components/includes/utils";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import "../styles/css/my-accountStyles.css";
import {
  getCustomerOrderbyID,
  getCustomerOrderList,
  updateCustomerbyID,
  UpdateOrder,
} from "../axiosCalls/pages";
import { useEffect } from "react";
import { useState } from "react";
import { async } from "q";
import { Form } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import NotificationContainer from "react-notifications/lib/NotificationContainer";
import { loginApi } from "../axiosCalls/auth";
import { ConfirmToast } from "react-confirm-toast";
import { useRef } from "react";
 
const MyAccount = () => {
  const [orderList, setOrderList] = useState();
  const [checkOrderDetails, setcheckOrderDetails] = useState(false);
  const [orderDetails, setOrderDetails] = useState();
  const [onlyOneTimeSetData ,setOnlyOneTimeSetData ] = useState(true)
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [GETOrderID,setOrderID] = useState();
  const ref = useRef(null);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    address_1: "",
    address_2: "",
    zipcode: "",
    mobile_number: "",
    password: "",
    newPassword: ""
  });
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  if (!token) {
    navigate("/login");
  }
const handleIdClickset = (e) =>{
  setOrderID(e.target.value)
}
  const handlerLogout = () => {
    isLogout();
  };
  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    const customerId = localStorage.getItem("user_id");

    const res = await getCustomerOrderList(customerId);
    setOrderList(res.data);
  };
  const handlerViewDetails = async (e) => {
// console.log(ref);

    let OrderBYID =   orderList.findIndex((pro) => pro.id == e.target.value);
       //  const orderres = await getCustomerOrderbyID(e.target.value);

    setOrderDetails(orderList[OrderBYID]);
    if (checkOrderDetails) {
      setcheckOrderDetails(false);
    } else {
      setTimeout(() => {
              ref.current.scrollIntoView({behavior: 'smooth'});

      }, 200);

      setcheckOrderDetails(true);
    }
  };
  const handleButtonClick = async () => {
    setLoading(true)
    // const result = window.confirm("Are you sure you want to Cancel the order?");
    // if (result) {
      // User clicked "Yes"
      // Perform your desired action here
      let data = JSON.stringify({
        status: "cancelled",
      });

      await UpdateOrder(data,GETOrderID);
      setLoading(false)
      window.location.reload();
     // } else {
      // User clicked "No" or closed the alert
      // Perform any necessary cleanup or display a message
      // console.log("Action canceled");
    // }
  };


  const ShippingDetails = JSON.parse(localStorage.getItem("shipping_details"));
 
 
  if (ShippingDetails && onlyOneTimeSetData) {
  if (ShippingDetails.first_name) {
    formData.firstName = ShippingDetails.first_name;
     
  }
  if (ShippingDetails.last_name) {
    formData.lastName = ShippingDetails.last_name;
  }
  if (ShippingDetails.address_1) {
    formData.address_1 = ShippingDetails.address_1;
  }
  if (ShippingDetails.address_2) {
    formData.address_2 = ShippingDetails.address_2;
  }
  if (ShippingDetails.postcode) {
    formData.zipcode = ShippingDetails.postcode;
  }
  if (ShippingDetails.phone) {
    formData.mobile_number = ShippingDetails.phone;
  }
  setOnlyOneTimeSetData(false)
}

  const handlerOnChangeDetails = (e) =>{

    const { name, value } = e.target;
    
     setFormData((prevData) => ({ ...prevData, [name]: value }));
  }
  const handlerUpdateDetails = async(e)=>{
    setLoading(true)

    var UpdateCustomerData = JSON.stringify({
      shipping: {
        first_name: formData.firstName,
        last_name: formData.lastName,
        address_1: formData.address_1,
        address_2: formData.address_2,

        postcode: formData.zipcode,

        phone: formData.mobile_number,
      }
      
    });
      const userID = localStorage.getItem("user_id");
      if (userID) {
        
        const cusRes = await updateCustomerbyID(userID, UpdateCustomerData);
        localStorage.setItem(
          "billing_details",
          JSON.stringify(cusRes.data.billing)
        );
        localStorage.setItem(
          "shipping_details",
          JSON.stringify(cusRes.data.shipping)
        );
        NotificationManager.success(
          "Details Updated","",1000
        );     
      }

    setLoading(false)

  }
  const handlerUpdatePassword = async(e)=>{
    console.log(formData)
    let NEWerrors = {};
    if(!formData.password){
      NEWerrors["password"] = "Current Password is required";
    setErrors(NEWerrors);

    }
     if(!formData.newPassword){
       
      NEWerrors["newPassword"] = "New Password is required";
          setErrors(NEWerrors);
    }
    if (Object.keys(NEWerrors).length <= 0) {
    setLoading(true)
    NEWerrors["newPassword"] = "";
    NEWerrors["password"] = "";
    setErrors(NEWerrors);
const email = localStorage.getItem("userEmail");
    await loginApi(email, formData.password).then(async(res) => {
      const UpdateCustomerData = JSON.stringify({
        "password": formData.newPassword
      })
      const userID = localStorage.getItem("user_id");
      const cusRes = await updateCustomerbyID(userID, UpdateCustomerData);
      setLoading(false);
      NotificationManager.success(
        "Password Updated","",1000
      );
    }).catch((err)=>{
       setLoading(false)
    })

   
 

    }else{
      setErrors(NEWerrors)
    }
  }



  return (
   
    <> 
    {loading &&
    <>
    <div className="myaccount-loader"> 
    <div className="overlay-loader" key="005"> </div>
    <div className="spinner" key="006"></div>
    </div>
  </>
    }
   
      <SidebarMenu menuType="left" />
      <SidebarMenu menuType="right" />
      <div className="my-account-page">
        <Tabs>
          <TabList>
            <Tab>
              <p>Dashboard</p>
            </Tab>
            <Tab>
              <p>Orders</p>
            </Tab>
            <Tab>
              <p>Account Details</p>
            </Tab>
            <Tab>
            <ConfirmToast message="Are you sure you want to logout ?" customFunction={handlerLogout}
                             theme={'snow'}
                             customCancel='NO'
                             position={'top'}
                             
                             asModal={true}
                             customConfirm='YES' > Logout</ConfirmToast>
            </Tab>
          </TabList>

          <TabPanel>
            <div className="panel-content">
              <h2>Dashboard</h2>

              <div className="row main-cards">
                <div className="col-md-3 card-item">
                  <h2 className="card-title">
                    {orderList && orderList.length}
                  </h2>
                  <h3 className="card-heading">Total Orders</h3>
                  <h4 className="card-sub-heading"></h4>
                </div>

                <div className="col-md-3 card-item">
                  <h2 className="card-title">
                    {orderList &&
                      orderList.filter((order) => order.status === "processing")
                        .length}
                  </h2>
                  <h3 className="card-heading">Processing Orders</h3>
                  <h4 className="card-sub-heading"></h4>
                </div>

                <div className="col-md-3 card-item">
                  <h2 className="card-title">
                    {orderList &&
                      orderList.filter((order) => order.status === "completed")
                        .length}
                  </h2>
                  <h3 className="card-heading">Completed Orders</h3>
                  <h4 className="card-sub-heading"></h4>
                </div>
              </div>

              <div className="row main-cards">
                <div className="col-md-3 card-item">
                  <h2 className="card-title">
                    {orderList &&
                      orderList.filter((order) => order.status === "cancelled")
                        .length}
                  </h2>
                  <h3 className="card-heading">Canceled Orders</h3>
                  <h4 className="card-sub-heading"></h4>
                </div>
                <div className="col-md-3 "></div>
                <div className="col-md-3"></div>
              </div>
            </div>
          </TabPanel>
          <TabPanel className="order-panel">
            <div className="panel-content table-responsive">
              <h2>Orders</h2>
              <table className="order-table">
                <thead>
                  <tr>
                    <th>Order#</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {orderList &&
                    orderList.map((order, index) => {
                      const dateTime = new Date(order.date_created);

                      const dateFormatOptions = {
                        month: "numeric",
                        day: "numeric",
                        year: "numeric",
                      };
                      const formattedDate = dateTime.toLocaleDateString(
                        "en-US",
                        dateFormatOptions
                      );
                      const timeFormatOptions = {
                        hour: "numeric",
                        minute: "numeric",
                        second: "numeric",
                        hour12: true,
                      };
                      const formattedTime = dateTime.toLocaleTimeString(
                        "en-US",
                        timeFormatOptions
                      );

                      return (
                        <tr>
                          <td style={{width:"10%"}}>{order.id}</td>
                          <td style={{width:"20%"}}>
                            {formattedDate} {formattedTime}
                          </td>
                          <td style={{width:"10%"}}>{order.status}</td>
                          <td  className="myaccount-btn-group"  >
                           
                           
                           
                            <button
                              value={order.id}
                              onClick={handlerViewDetails}
                              style={{ width: "30%" }}
                              className="btn btn-danger view-myacc-bnt"
                            >
                              View
                            </button>
                            {order.status === "processing" && (
                             <ConfirmToast message="Are you sure you want to Cancel the order?" customFunction={handleButtonClick}
                             theme={'snow'}
                             customCancel='NO'
                             position={'top'}
                             value={order.id}
                             asModal={true}
                             customConfirm='YES' >
                             <button
                                value={order.id}
                                onClick={handleIdClickset}
                                style={{ width: "100%", marginLeft: "10px" }}
                                className="btn btn-danger view-myacc-bnt buttonCancelOrder"
                              >
                                Order Cancel
                              </button>
                                            </ConfirmToast>

                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div   ref={ref}> 
            {checkOrderDetails && (
              <fieldset >
                <>
                  <div
                  
                    className="Billing-address-box"
                    style={{ marginBottom: "30px" }}
                  >
                    <div className="thank-you-page">
                      <strong>{orderDetails.billing.first_name}</strong>{" "}
                      {orderDetails.billing.last_name} <br />
                      Thank you for your order <br />
                      we can't wait to see you being les coy! <br />
                      {"#"}belesscoy {"#"}bibi <br />
                      <br />
                      <br />
                      Your Order #{orderDetails.id} is being processed. You will
                      receive a confirmation email soon.
                    </div>
                  </div>

                  <div
                    className="Billing-address-box"
                    style={{ marginBottom: "30px" }}
                  >
                    <div className="thank-you-page table-responsive">
                      <table className="" style={{ width: "100%" }}>
                        <thead>
                          <th>Delivery Address</th>
                          <th>Shipping Method</th>
                          <th>Payment Method</th>
                          <th>Email</th>
                          <th>Gift</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {orderDetails.billing.address_1}
                              <br />
                              {orderDetails.billing.address_2}
                              <br />
                              {orderDetails.billing.city}
                              <br />
                            </td>
                            <td>
                              {orderDetails.shipping_lines[0].method_title}
                              <br />
                              {orderDetails.currency_symbol}{" "}
                              {orderDetails.shipping_lines[0].total}
                            </td>
                            <td>{orderDetails.payment_method_title}</td>
                            <td>{orderDetails.billing.email}</td>
                            <td>{orderDetails.meta_data.filter(obj => obj.key === "isGifting")[0].value ? "YES" : "NO" }</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              </fieldset>
            )}
            </div>
          </TabPanel>

          <TabPanel>
            <div className="panel-content">
              <h2>Account Details</h2>

              <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-4">
                  <fieldset  className="myaccount-fieldset">
                    <h3>Update Password</h3>
                    <form>
                      <input
                        type="password"
                        className="form-control "
                        name="password"
                        onChange={handlerOnChangeDetails}
                        placeholder="Enter Current Password"
                      />
                      <Form.Text className="text-muted">
              {errors.password && (
                <p className="errorText">{errors.password}</p>
              )}
            </Form.Text>
                      <br />
                      <input
                        type="password"
                        className="form-control "
                        name="newPassword"
                        onChange={handlerOnChangeDetails}
                        placeholder="Enter New Password"
                      />
                        <Form.Text className="text-muted">
              {errors.newPassword && (
                <p className="errorText">{errors.newPassword}</p>
              )}
            </Form.Text>
                      <br />
                      <button
                        type="button"
                        value="Update Password"
                        style={{width:"100%"}}
                        onClick={handlerUpdatePassword}
                        className="btn btn-danger view-myacc-bnt updateBtn updatepassword"
                      >Update Password</button>
                    </form>
                  </fieldset>
                </div>
                <div className="col-md-1"></div>

                <div className="col-md-4">
                  <fieldset className="myaccount-fieldset">
                    <h3>Update Details</h3>
                    <input
                      type="text"
                      className="form-control "
                      id="firstName"
                      value={formData.firstName}
                      name="firstName"
                      onChange={handlerOnChangeDetails}
                      placeholder="First name"
                      required
                    />
                    <br />
                    <input
                      type="text"
                      className="form-control "
                      id="lastName"
                      onChange={handlerOnChangeDetails}
                      value={formData.lastName}
                      name="lastName"
                      placeholder="Last name"
                      required
                    />
                    <br></br>
                    <input
                      type="text"
                      className="form-control "
                      id="address_1"
                      name="address_1"
                      onChange={handlerOnChangeDetails}
                      value={formData.address_1}
                      placeholder="Address 1"
                      required
                    />{" "}
                    <br></br>
                    <input
                      type="text"
                      className="form-control "
                      id="address_2"
                      onChange={handlerOnChangeDetails}
                      value={formData.address_2}
                      name="address_2"
                      placeholder="Address 2"
                      required
                    />
                    <br></br>
                    <input
                      type="number"
                      className="form-control "
                      id="mobile_number"
                      onChange={handlerOnChangeDetails}
                      value={formData.mobile_number}
                      name="mobile_number"
                      placeholder="Mobile Number"
                      required
                    />
                    <br></br>
                    <input
                      type="text"
                      className="form-control "
                      id="zipcode"
                      onChange={handlerOnChangeDetails}
                      value={formData.zipcode}
                      name="zipcode"
                      placeholder="Zipcode"
                      required
                    />
                    <br></br>


                    <button
                      type="button"
                      value="Update Details"
                      onClick={handlerUpdateDetails}
                      style={{width:"100%"}}
                      className="btn btn-danger view-myacc-bnt updateBtn updatepassword"
                    >Update Details</button>
                  </fieldset>
                </div>
              </div>
            </div>
          </TabPanel>
        </Tabs>
      </div>
      <NotificationContainer />
    </>
  );
};

export default MyAccount;
