import React, { useEffect, useState } from "react"; // Importing the React library
import CreateRoutes from "./routes"; // Importing the CreateRoutes component from a file in the same directory called "routes"
import "../src/styles/bootstrap/css/bootstrap.css";
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Make sure you include the bundle version
import "../src/styles/css/header-footer.css";
import "../src/styles/css/main.css";
import "../src/styles/css/responsiveness/home-reponsive.css";
import "../src/styles/css/responsiveness/slider-style.css";
import "../src/styles/css/checkout.css";
import "../src/styles/css/my-account.css";
import "../src/styles/css/responsiveness/mobileDesigns.css"
import "react-notifications/lib/notifications.css";
 
import "@fontsource/fira-sans"; // Defaults to weight 400.
import { useDispatch, useSelector } from "react-redux";
import {
  addToCartFee,
  allPageApi,
  getCartApi,
  getMediaById,
  PageApi,
  productsAllGet,
} from "./axiosCalls/pages";
import {
  setAddToCart,
  setWhyPage,
  setProducts,
  setSelectedProduct,
  setProcessPage,
  setFaqPage,
  setConnectPage,
} from "./redux/actions/pagesActions";
 import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
import { useCallback } from "react";


function App() {
  const [state, setstate] = useState(true);

  const [loading, setLoading] = useState(false);


  const storedData = JSON.parse(localStorage.getItem("storedDataTime"));
  const storedTime = new Date(storedData);
  const currentTime = new Date();
  const timeDifference = currentTime.getTime() - storedTime.getTime();

  // Check if the time difference is greater than one hour (in milliseconds)
  const oneHourInMilliseconds = 60 * 30 * 1000;
   if (timeDifference > oneHourInMilliseconds) {
    // Remove the data from localStorage

    localStorage.removeItem("persist:leskoy");
    localStorage.removeItem("apisExecuted");
  }
 
  const dispatch = useDispatch();
  const pages = useSelector((state) => state);
  
   const fetchdata = useCallback(async () => {
    let cartKey = localStorage.getItem("cart_key");
    localStorage.setItem("apisExecuted", true);

    if (!localStorage.getItem("cart_key")) {
      cartKey = generateRandomString(17);
    }

    const cartGet = {
     
        "cart_hash": "20aa8bd0fef9e9f711ffa92c9b7a4d8e",
        "cart_key": "smzuIoYAg2uG7XpdA",
        "currency": {
            "currency_code": "USD",
            "currency_symbol": "$",
            "currency_minor_unit": 2,
            "currency_decimal_separator": ".",
            "currency_thousand_separator": ",",
            "currency_prefix": "$",
            "currency_suffix": ""
        },
        "customer": {
            "billing_address": {
                "billing_email": "",
                "billing_first_name": "",
                "billing_last_name": "",
                "billing_company": "",
                "billing_country": "US",
                "billing_address_1": "",
                "billing_address_2": "",
                "billing_city": "",
                "billing_state": "CA",
                "billing_postcode": "",
                "billing_phone": ""
            },
            "shipping_address": {
                "shipping_first_name": "",
                "shipping_last_name": "",
                "shipping_company": "",
                "shipping_country": "US",
                "shipping_address_1": "",
                "shipping_address_2": "",
                "shipping_city": "",
                "shipping_state": "CA",
                "shipping_postcode": ""
            }
        },
        "items": [],
        "item_count": 0,
        "items_weight": 0,
        "coupons": [],
        "needs_payment": false,
        "needs_shipping": false,
        "shipping": [],
        "fees": [],
        "taxes": [],
        "totals": {
            "subtotal": "0",
            "subtotal_tax": "0",
            "fee_total": "0",
            "fee_tax": "0",
            "discount_total": "0",
            "discount_tax": "0",
            "shipping_total": "0",
            "shipping_tax": "0",
            "total": "0",
            "total_tax": "0"
        },
        "removed_items": [],
        "cross_sells": [],
        "notices": []
    

    }
  
    localStorage.setItem("cart_items", JSON.stringify(cartGet));
  
    localStorage.setItem("cart_key", cartKey);
  
    dispatch(setAddToCart(cartGet));
  
    //  all apis
 
   

     const allPages = await allPageApi();

      
    if(!loading && allPages){
          setLoading(true);

    }

    //  const allPageHandler = () => {}

    const resPro = await productsAllGet();

    // why page
    // const res = await PageApi(66);
    let whyPageIndex = allPages.data.findIndex((pro) => pro.id == 66);
    // const Feture_image = await getMediaById( allPages.data[whyPageIndex].featured_media);

    const whydata = [
      {
        image: allPages.data[whyPageIndex].featured_image,
        title: allPages.data[whyPageIndex].title.rendered,
        content: allPages.data[whyPageIndex].content.rendered,
        excerpt: allPages.data[whyPageIndex].excerpt.rendered,
        slug:'why',
        status:'publish'
      },
    ];
    dispatch(setWhyPage(whydata));

    // process  page
    let processIndex = allPages.data.findIndex((pro) => pro.id == 145);
    // const processres = await PageApi(145);
    //  const processFeture_image = await getMediaById(allPages.data[processIndex].featured_media);

    const processdata = [
      {
        image: allPages.data[processIndex].featured_image,
        title: allPages.data[processIndex].title.rendered,
        content: allPages.data[processIndex].content.rendered,
        excerpt: allPages.data[processIndex].excerpt.rendered,
        slug:'process',
        status:'publish'
      },
    ];
    dispatch(setProcessPage(processdata));

    // Connect  page
    let connectIndex = allPages.data.findIndex((pro) => pro.id == 147);

    // const connectres = await PageApi(147);
    //  const connectFeture_image = await getMediaById(allPages.data[connectIndex].featured_media);

    const connectdata = [
      {
        image: allPages.data[connectIndex].featured_image,
        title: allPages.data[connectIndex].title.rendered,
        content: allPages.data[connectIndex].content.rendered,
        excerpt: allPages.data[connectIndex].excerpt.rendered,
        slug:'connect',
        status:'publish'
      },
    ];
    dispatch(setConnectPage(connectdata));

    // Faqs  page
    let faqIndex = allPages.data.findIndex((pro) => pro.id == 149);

  

    const faqdata = [
      {
        image: allPages.data[faqIndex].featured_image,
        title: allPages.data[faqIndex].title.rendered,
        content: allPages.data[faqIndex].content.rendered,
        excerpt: allPages.data[faqIndex].excerpt.rendered,
        slug:'faq',
        status:'publish'
      },
    ];
    dispatch(setFaqPage(faqdata));
 
    dispatch(setProducts(resPro.data));

    const selectedIndex = resPro.data.findIndex(
      (item) => item.type === 'variable'
    )
    const product = resPro.data[selectedIndex];
    const setProduct = {
      id: product.id,
      name: product.name,
      price: product.price,
      status: product.status,
      featuredimg: product.images[0].src,
      images: product.images,
      attribute_images: product.attribute_images,
      attributes: product.attributes,
      variations: product.variations,
    };
     dispatch(setSelectedProduct(setProduct));

    
    // const res2 = await addToCartFee("30",cartKey);

  }, []);



  const page = useSelector((state) => state);


  if (page.page.page != "" && !loading && pages.product.product.id) {
    setLoading(true);
  }


  useEffect(() => {

    if (!isFileExecuted) {
      fetchdata();
       const timestamp = new Date().getTime();
  
      localStorage.setItem("storedDataTime", JSON.stringify(timestamp));
    }


  }, []);

  const isFileExecuted = localStorage.getItem("apisExecuted");

  if (isFileExecuted) {
    if (!pages.page.page[0]) {
      localStorage.removeItem("apisExecuted");
      localStorage.removeItem("persist:root");
    }
  }

  // if (!isFileExecuted) {
  //   fetchdata();
  //    const timestamp = new Date().getTime();

  //   localStorage.setItem("storedDataTime", JSON.stringify(timestamp));
  // }
  const handleClick = (event) => {
    // Handle your click event here
    if (event.target.id !== 'cart-icon') {
 
    document.getElementById("cart-box").style.width = "0px";
    document.getElementById("cart-box").style.display = "none";
   
    }
    if(event.target.className !== 'menuIcon'  ){
      const mobileScreenWidth = 767;
      if (window.innerWidth <= mobileScreenWidth) {
      document.getElementById("rightMobileMenu").style.visibility = "hidden !important";
    document.getElementById("rightMobileMenu").style.opacity = "0";

    document.getElementById("leftMobileMenu").style.visibility = "hidden";
    document.getElementById("leftMobileMenu").style.opacity = "0";
      }
    }
 
  
  };
  document.addEventListener('click', handleClick);
  return (
    <>
      {!loading ? (
        <>
          <div className="overlay-loader"></div>
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        </>
      ) : (
        
        <>
               

        <ToastContainer
position="top-center"
autoClose={3000}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
theme="light"
/>
        <CreateRoutes />
        
        </>
      )}
    </>
  );
}
function generateRandomString(length) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
}
export default App; // Exporting the App component as the default export
