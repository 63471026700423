import React, { useState, useRef, useEffect } from "react";
import greenLogo from "../assets/images/greenLogoLanding.png";
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import video from "../assets/videos/find-bg-video.mp4";
 import logo from "../assets/images/logo.png";
import { getMediaById, PageApi } from "../axiosCalls/pages";
import { setPage } from "../redux/actions/pagesActions";
import muteIcon from "../assets/images/icons/mute.svg";
import unmuteIcon from "../assets/images/icons/unmute.svg";
import playIcon from "../assets/images/icons/play.svg";
import pause from "../assets/images/icons/pause.svg";
import entersiteicon from "../assets/images/icons/entersiteicon.svg";
import { paste } from "@testing-library/user-event/dist/paste";
import SidebarMenu from "../components/header/sidebar-menu";
const Landing = () => {
  const [showText, setShowText] = useState(false);
  const [videoState, setVideoState] = useState(false);
  const [videoStateplay, setVideoStateplay] = useState(true);
  const navigate = useNavigate();
  const videoRef = useRef(null);
 
  const handleToggleplay = () => {
    const video = videoRef.current;

    // if(videoStateplay){
    //   video.pause();
    //   setVideoStateplay(false)
    // }
    // if(!videoStateplay){
    //   video.play();
    //   setVideoStateplay(true)
    // }

    if (video.paused) {
      video.play();
      setVideoStateplay(true);
    } else {
      video.pause();
      setVideoStateplay(false);
    }
  };
  const handleToggleMute = () => {
    const video = videoRef.current;
    video.muted = !video.muted; // Toggle the muted property

    if (video.muted) {
      video.volume = 0; // Set volume to 0 if muted
      setVideoState(false);
    } else {
      video.volume = 1; // Set volume to 1 if unmuted
      setVideoState(true);
    }
  };

  setTimeout(function() {
    if (!videoRef.current) {
      return;
    } else {
      const videos = videoRef.current;
       if (!videoState) videos.muted = true;
       
      //  if (videos.paused) {
      //   videos.play();
      //  }

      // videos.play();
    }
  }, 2);

  // video.muted = false;

  const handleTimeUpdate = (event) => {
    const video = event.target;
    if (video.currentTime == 2) {
    }
    if (video.currentTime >= 18 && !showText && video.currentTime <= 25) {
      setShowText(true);
    } else if (video.currentTime >= 26 && showText) {
      setShowText(false);
    } else if (video.currentTime >= 30 && !showText) {
      navigate("/home");
    }
  };
  const handlerClick = () => {
  
    navigate("/home");
  };

  return (
    <>



    <div className="landing-page-icons">
      <div className="muteUnmuteIcon">
        <img
          src={videoState ? muteIcon : unmuteIcon}
          onClick={handleToggleMute}
          width="25px"
          height="25px"
          style={{ width: "25px" }}
        />
        <img
          src={!videoStateplay ? playIcon : pause}
          onClick={handleToggleplay}
          style={{ marginLeft: "20px"  }}
        />
       
      </div>
     
    


      </div>
      <div className="landing-icon-enterSite">
          <NavLink to="/home">
          <img className="enterSiteBtn" src={entersiteicon} />
        </NavLink>
      </div>
      <div className="mainHomepage" onClick={handlerClick} id="mainHomepage" style={{height:"100vh"}}>
   
   
        <video
          id="background-video"
          onTimeUpdate={handleTimeUpdate}
          ref={videoRef}
          onClick={handlerClick}
          autoPlay
          playsInline // Add the playsinline attribute
          muted
          loop
        >
          <source src={video} type="video/mp4" />
        </video>

        {showText ? (
          <>
            {/* <h1 className="heading-Mainpage">Be Less Coy</h1> */}

            <div className="mobile-logo">
              <img src={logo} />
            </div>
            <div className="greenLogo">
              {" "}
              <NavLink to="/home">
                <img src={greenLogo} />
              </NavLink>
              <NavLink to="/scarves">
              <h1 className="sub-heading-Mainpage">Shop the Collection</h1>
            </NavLink>
            </div>
           
          </>
        ) : (
          <h1 className="sub-heading-Mainpage"></h1>
        )}
      </div>
      <div  style={{display:"none"}}>

<SidebarMenu menuType="left" />
      <SidebarMenu menuType="right" />
</div>
    </>
  );
};

export default Landing;
