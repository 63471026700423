export const isLogin = () => {
    if (localStorage.getItem('token')) {
        return true;
    }

    return false;
}
export const isLogout = () => {
    

    localStorage.removeItem('token');
    localStorage.removeItem('user_id');
    localStorage.removeItem('shipping_details');
    localStorage.removeItem('billing_details');
    
    // window.location.reload(true);
    window.location.href = "/login";
}