import React, { useEffect, useRef, useState } from "react";
import SidebarMenu from "../components/header/sidebar-menu";
import { NavLink, useParams } from "react-router-dom";
import bottonRightLogo from "../assets/images/right-bottom-logo.png";
import leftIcon from "../assets/images/icons/left-arrow.svg";
import rightIcon from "../assets/images/icons/right-arrow.svg";
import plusicon from "../assets/images/icons/plus-icon.png";
import minusicon from "../assets/images/icons/minus-icon.png";
import ReactHtmlParser from 'react-html-parser';
 
import { useDispatch, useSelector } from "react-redux";
import $ from 'jquery';
import 'jquery-touchswipe';
import {
  productsDetails,
  productsVariationDetails,
} from "../redux/allapiscall/prodcuts";
import {
  setAddToCart,
  setSelectedProduct,
} from "../redux/actions/pagesActions";
import { async } from "q";
import { addToCart, addToCartFee, getCartApi, getPaymentIntent, RemoveAddToCartFee, UpdatePaymentIntent } from "../axiosCalls/pages";
import { toast } from "react-toastify";
 

const ScarvesDetailPage = () => {
  const { id } = useParams();
  const carouselRef = useRef(null);
  const nextRef = useRef(null);
  const prevRef = useRef(null);

  // const $ = require( "jquery" )( window );

useEffect(() => {
  setTimeout(() => {
    
    $(carouselRef.current).swipe({
      swipe: function (event, direction, distance, duration, fingerCount, fingerData) {
        if (direction === 'left') {
            nextRef.current.click();
         }
        if (direction === 'right') {
           prevRef.current.click();
                }
      },
      allowPageScroll: "vertical"
    });

   }, 1000);
  
 


}, []);
  const [allowContent, setallowContent] = useState(false);

  let check = true;
  const showContent = () =>{
    if(allowContent)
    { 
     
       setallowContent(false)
    }
      else
     { 
     
      setallowContent(true)
    }
    } 
   
  const getProduct = useSelector((state) => state);
  const dispatch = useDispatch();
  let allarray = getProduct.products.products.findIndex((pro) => pro.id == id);
  const product = getProduct.products.products[allarray];
   if(!product){
    window.location.assign("/scarves");
   }
   const setProduct = {
    id: product.id,
    name: product.name,
    price: product.price,
    status: product.status,
    featuredimg: product.images[0].src,
    images: product.images,
    attribute_images: product.attribute_images,
    attributes: product.attributes,
    variations: product.variations,
  };
  const [loading, setLoading] = useState(false);

  const [carsoulaArray , setCarArray] = useState(product.images);
  const [checkArraySlic,setcheckArraySlic] = useState(false)
  //   dispatch(setSelectedProduct(setProduct));

  useEffect(() => {

    

    //   console.log(product);
    // dispatch(setSelectedProduct(setProduct));
  }, []);
  const cartData = useSelector((state) => state).addtocart.addtocart;
 

  if(!localStorage.getItem("payment_intent"))
  localStorage.setItem("payment_intent",false)


  const handlerAddToCart = async () => {
    
    if(cartData.item_count > 4 ){
                // alert("Please contact sales@leskoy.com if you would like to order more than 5 pieces");
                // NotificationManager.success("Please contact sales@leskoy.com if you would like to order more than 5 pieces","",5000)
                toast.info("Please contact sales@leskoy.com if you would like to order more than 5 pieces")

                setLoading(false);
              }else{

         

    setLoading(true);
    const cartKey = localStorage.getItem("cart_key");
    let sendToCart = JSON.stringify({
      id: id,
      quantity: "1",
      cart_key: cartKey,
    });


    let res = await addToCart(sendToCart);
     localStorage.setItem("cart_key", res.data.cart_key);
   
 const { fees } = res.data;

    if (Object.keys(fees).length === 0) { 
      
       const res2 = await addToCartFee("30");
    }
    // const resRemoveFee = await RemoveAddToCartFee();
    // console.log(e.target.value);
    const cartGet = await getCartApi(cartKey);
    
    localStorage.setItem("cart_items", JSON.stringify(cartGet.data));
    dispatch(setAddToCart(cartGet.data));

   
   setLoading(false);
    // if(localStorage.getItem("payment_intent") === 'false' || localStorage.getItem("payment_intent") === false  || !localStorage.getItem("payment_intent")){
    //    const resPayment = await getPaymentIntent(cartGet.data.totals.total);  
    //    localStorage.setItem("client_secret", resPayment.data.data.client_secret);
    // localStorage.setItem("client_secret_ID", resPayment.data.data.id);
    // localStorage.setItem("payment_intent",true)

    // }else{
    //   let price  = res.data.totals.total;
    //   if(price <=0 || !price || price === null){
    //     price = 1;
    //   }
      
    //   await UpdatePaymentIntent(cartGet.data.totals.total, localStorage.getItem("client_secret_ID")  )
    // }
   
   
  

   
    const mobileScreenWidth = 767;

    if (window.innerWidth <= mobileScreenWidth) {
      document.getElementById("cart-box").style.width = "385px";
      document.getElementById("cart-box").style.display = "block";
      localStorage.setItem("cart-toggal", true);
    } else {
      document.getElementById("cart-box").style.width = "750px";
      document.getElementById("cart-box").style.display = "block";
      localStorage.setItem("cart-toggal", true);
    }
  }
  };


const AddToCartItem = async () =>{
  const cartItemsData = JSON.parse(localStorage.getItem('cart_items'));
  if(cartItemsData.item_count > 4 ){
       // alert("Please contact sales@leskoy.com if you would like to order more than 5 pieces");
                // NotificationManager.success("Please contact sales@leskoy.com if you would like to order more than 5 pieces","",5000)
                toast.info("Please contact sales@leskoy.com if you would like to order more than 5 pieces")

                setLoading(false);
              }else{
                // setLoading(true);
                
       
// Retrieve the cart data from local storage

// Check if the item already exists in the cart
const existingItem = cartItemsData.items.find(item => item.id === setProduct.id);

if (existingItem) {
  // Item already exists, update the quantity and totals
  existingItem.quantity.value += 1;
  existingItem.totals.subtotal = setProduct.price * existingItem.quantity.value;
  existingItem.totals.total = existingItem.totals.subtotal;
} else {
  // Item is new, create a new item object
  const newItem = {
    item_key: "1c9ac0159c94d8d0cbedc973445af2da",
    id: setProduct.id,
    name: setProduct.name,
    title: setProduct.name,
    price: setProduct.price,
    quantity: {
      value: 1,
      min_purchase: 1,
      max_purchase: -1
    },
    totals: {
      subtotal: setProduct.price,
      subtotal_tax: 0,
      total: setProduct.price,
      tax: 0
    },
    cart_item_data: [],
    meta: {
      product_type: "simple"
    },
    featured_image: setProduct.featuredimg,
    product_id: 0
  };

  // Add the new item to the items array
  cartItemsData.items.push(newItem);
}

const cartValuesMaintain =  {
  fees: {
    "shipping-and-handling": {
      name: "Shipping and handling",
      fee: "30"
    }
  },
  item_count: cartItemsData.items.length,
  totals : cartItemsData.totals

}
cartValuesMaintain.totals.fee_total = 30
// Calculate item_count as the sum of all item quantities
cartValuesMaintain.item_count = cartItemsData.items.reduce(
  (sum, item) => sum + item.quantity.value,
  0
);
// Update the item count and totals in the cart data
cartItemsData.item_count = cartValuesMaintain.item_count ;
cartItemsData.totals.subtotal =parseFloat( cartItemsData.items.reduce((sum, item) => parseFloat(sum) +parseFloat( item.totals.subtotal), 0)).toFixed(2);

cartItemsData.totals.total = (parseFloat(cartItemsData.totals.subtotal) + parseFloat(cartValuesMaintain.totals.fee_total)).toFixed(2);

cartItemsData.fees = cartValuesMaintain.fees;
 
// Save the updated cart data back to local storage
localStorage.setItem('cart_items', JSON.stringify(cartItemsData));
dispatch(setAddToCart(cartItemsData));

const mobileScreenWidth = 767;
setTimeout(() => {
  if (window.innerWidth <= mobileScreenWidth) {
  document.getElementById("cart-box").style.width = "385px";
  document.getElementById("cart-box").style.display = "block";
  localStorage.setItem("cart-toggal", true);
} else {
  
  document.getElementById("cart-box").style.width = "750px";
  document.getElementById("cart-box").style.display = "block";
  localStorage.setItem("cart-toggal", true);
}
}, 200);
}

}
  
  return (
    <>
      {loading ? (
        <>
          <div className="loader-cart">
            <div className="overlay-loader"></div>
            <div className="loader-container">
              <div className="spinner"></div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      <div className="scarves-details">
        <SidebarMenu menuType="left" />
        <SidebarMenu menuType="right" />
      </div>
      <div className="row scarves-page scarves-details">
        <NavLink to="#">
          {" "}
          {/* <h1 className="sub-heading">Shop the Collection</h1> */}
        </NavLink>

        <div className="col-md-1"></div>

        <div className="col-md-11 center-page ">
 
          <span className="product-title-details-page">{product.name}</span>
          <div className="row  slide-in" style={{ padding: "1% 10% 1% 5%" }}>
            <div className="col-md-0"></div>
            <div className="col-md-12">

              <div>
                <div
                  id="myCarousel"
                  ref={carouselRef}
                  className="carousel slide"
                  data-touch="true"
                  data-ride="carousel"
                  data-interval="4000"
                >
                  <ol className="carousel-indicators">
                    {carsoulaArray.slice(1).map((img, index) => {
                      return   (
                       
                        <li
                        data-target="#myCarousel"
                        data-slide-to={index}
                          className={`${index == 0 ? "active" : ""}`}
                        >
                          <span></span>

                        </li>
                      );
                    })}
                  </ol>

                  <div className="carousel-inner">
                    {carsoulaArray.slice(1).map((img, index) => {
                       return  (
                      
                        <div
                          className={`item ${index == 0 ? "active" : ""}`}
                          key={index}
                        >
                          <img src={img.src} alt="Be Less coy" />

                          <div className={allowContent ? "slider-content-height slider-text" : "slider-text"}    >

                            {" "}
                            <p>{allowContent ? ReactHtmlParser(product.description) : removeHtmlTags(product.short_description)}</p>
                            <div className="plus-icon-slider-text"  onClick={showContent}>
               <img src={allowContent ? minusicon : plusicon} />

            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  <a
                    className="left carousel-control"
                    href="#myCarousel"
                    data-slide="prev"
                    ref={prevRef}
                  >
                    <img src={leftIcon} />
                  </a>
                  <a
                    className="right carousel-control"
                    href="#myCarousel"
                    data-slide="next"
                    ref={nextRef}
                  >
                    <img src={rightIcon} />
                  </a>
                </div>
              </div>
  <div className="buy-text-after-attribute">
                <div className="product-price-slider">Silk</div>
                <div className="product-price-slider">160x80cm</div>

               </div>


              <div className="buy-text-after-slider">
                <div className="product-price-slider">{(ReactHtmlParser(product.price_html))}</div>
                <NavLink onClick={AddToCartItem}>ADD TO CART</NavLink>
              </div>
<div className="product-description">
  {/* {allowContent && ReactHtmlParser(product.description)} */}
</div>
            


            </div>
            <div className="col-md-2"></div>
            <div className="spacer"></div>
          </div>

          <div className="bottomLogo">
            {/* <img className="bottom-right-logo" src={bottonRightLogo} /> */}
          </div>
        </div>
      </div>
    </>
  );
};
function removeHtmlTags(html) {
  const doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
}

function removeHtmlBRTags(html) {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  const body = doc.body;

  const brTags = body.getElementsByTagName('br');
  for (let i = brTags.length - 1; i >= 0; i--) {
    const brTag = brTags[i];
    brTag.parentNode.removeChild(brTag);
  }
 // Remove elements with class name '.container'
 const containerElements = body.querySelectorAll('.container');
    containerElements.forEach((element) => {
      element.classList.remove('container');
    });

  return body.innerHTML;
}

export default ScarvesDetailPage;
