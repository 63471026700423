import React , {useState} from "react";
import { Button, Form } from "react-bootstrap";
import logo from "../assets/images/logo.png";
import { NavLink, useNavigate } from "react-router-dom";
import { ForgotPasswordApi } from "../axiosCalls/auth";
import NotificationContainer from "react-notifications/lib/NotificationContainer";
import NotificationManager from "react-notifications/lib/NotificationManager";



const ForgotPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formValue, setFormValue] = useState({
    email: ""
  });
  const [errors, setErrors] = useState({});
  const handleOnchange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
    
  };
  const submitHandler = async () => {
    const { email } = formValue;
    let errors = {};
     if (!email) {
      errors["email"] = "Email is required";
      setErrors(errors);
    } else if (Object.keys(errors).length <= 0) {
      errors["email"] = "";
       setErrors(errors);
       setLoading(true)
        await  ForgotPasswordApi(email).then((res)=>{
            localStorage.setItem('email-otp',email);
            setLoading(false)
            NotificationManager.success(
             res.data.message,"",1000
            );
           
            setTimeout(function() {
              console.clear();

              navigate('/verify-otp');
            }, 3000);
         }).catch((err)=>{
          setLoading(false)
         })


    } else {
      setErrors(errors);
    }
  };
  onkeydown = (e) => {
    if (e.keyCode === 13) {
      
    submitHandler();
    }
  };
  return (
    <>
     {loading &&
    <>
    <div className="myaccount-loader"> 
    <div className="overlay-loader" key="005"> </div>
    <div className="spinner" key="006"></div>
    </div>
  </>
    }
      <div className="center forgot-page">
      <NavLink to='/home'>
        <img className="logo" src={logo} />
      </NavLink>
      </div>

      <div className="center-form slide-in">
        <h1 className="main-heading">Forgot Password</h1>
        <p className="sub-heading">
          Please enter the email address associated with your account.
        </p>

       
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" name="email" placeholder="Enter email" onChange={handleOnchange} />
            <Form.Text className="text-muted">
              {errors.email && <p className="errorText">{errors.email}</p>}
            </Form.Text>
          </Form.Group>
          <Button className="submit-button" type="button" onClick={submitHandler}>
            Send
          </Button>

          <NavLink
            className="login-here center"
            style={{ marginTop: "20px" }}
            to="/login"
          >
            Back To Login
          </NavLink>
       
      </div>
      <NotificationContainer />
    </>
  );
};

export default ForgotPassword;
